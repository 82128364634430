.shop_categories {
  width: 100%;
  height: calc(100% - 45px);
  overflow: hidden;
  background: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  &_scrollmenu {
    width: 100%;
    background: #fff;
    overflow: auto !important;
    white-space: nowrap;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    align-items: center;
    &--dark {
      background: #fff;
      .shop_categories_scrollmenu_item {
        background: var(--dynamic-color) !important;
        color: #fff !important;
      }
    }
    &::-webkit-scrollbar {
      display: none !important;
    }
    &::-webkit-scrollbar-track {
      display: none !important;
    }
    &::-webkit-scrollbar-thumb {
      display: none !important;
    }
    &::-webkit-scrollbar-thumb:hover {
      display: none !important;
    }
    &_item {
      text-decoration: none;
      display: inline-block;
      text-align: center;
      padding: 0 14px;
      font-size: 14px;
      font-weight: 600;
      color: #25262b;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative; /* Add this to position the ::after pseudo-element */
      background: #fff;
      height: 30px;
      text-transform: uppercase;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 5px;
        background-color: rgba(#999, 0.2);
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 5px;
        background-color: var(--dynamic-color);
        transition: width 0.3s ease; /* Add this for the animation effect */
      }

      &:hover::after {
        width: 100%;
      }

      &--active::after {
        width: 100%;
      }
    }
  }
  &_title {
    height: 60px;
    padding: 0 14px 10px 14px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    background: #fff;
    width: 100%;
    &--dark {
      background: #fff;
      p {
        color: #fff !important;
      }
    }
    p {
      font-size: 18px;
      font-weight: 600;
      color: #25262b;
      display: inline;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      width: 20px;
      height: 20px;
      .loading {
        width: 20px;
        height: 20px;
        padding: 5px 0 0 0 !important;
      }
    }
  }
  &_products {
    width: 100%;
    overflow-y: auto;
    background: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 60px;
    &--dark {
      background: #fff !important;
      .shop_header_in_search_modal_in_title {
        color: #fff !important;
      }
      .shop_header_in_search_modal_in_description {
        color: #fff !important;
      }
      .shop_categories_footer {
        background: var(--dynamic-color);
      }
      .shop_header_in_search_modal_in {
        background: var(--dynamic-color) !important;
      }
      .shop_header_in_search_modal_in_title {
        color: #fff !important;
      }
      .shop_header_in_search_modal_in_description {
        color: #fff !important;
      }
    }
  }
  &_footer {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    background: transparent;
    padding-right: 5px;
    &--short {
      width: calc(100% - 500px);
    }
  }
}

@media only screen and (max-width: 1000px) {
  .shop_categories_title {
    height: 40px;
    padding: 0 14px 10px 14px;
  }
}
@media only screen and (max-width: 600px) {
  .shop_categories_products {
    display: block;
  }
  .shop_categories_footer {
    padding-right: 0;
  }
}

.booking {
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  &_header {
    height: 100%;
    width: 100%;
    background-color: var(--dynamic-color);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 60px;
    &--dark {
      background-color: var(--dynamic-color);
    }
    img {
      width: 200px;
      object-fit: contain;
    }
    button {
      background: #fff;
      border-radius: 100px;
      border: none;
      cursor: pointer;
      padding: 8px 16px;
      transition: all 0.3s ease;
      font-weight: 600;
      color: var(--dynamic-color);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.22);
      position: absolute;
      top: 70px;
      right: 25px;
      font-size: 12px;
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
  &_form {
    position: absolute;
    top: 30%;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.22);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    transform: translateY(100%);
    padding: 50px 10px;
    &--active {
      animation: showSignUpForm 0.5s ease;
      transform: translateY(0);
    }
    &_title {
      width: 100%;
      text-align: center;
      font-weight: 800;
      font-size: 32px;
      color: var(--dynamic-color);
      padding-bottom: 5px;
      text-transform: uppercase;
    }
    &_description {
      width: 80%;
      text-align: center;
      font-weight: 300;
      font-size: 14px;
      color: var(--dynamic-color);
    }
    &_btn {
      border: 1px solid #25262b;
      background: #25262b;
      color: #fff;
      transition: all 0.3s;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.055em;
      padding: 6px 8px;
      width: 600px;
      height: 40px !important;
      cursor: pointer;
      border-radius: 6px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      .loading {
        padding: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        transform: translateY(-3px);
      }
      &--delete {
        background: rgba(#e74c3c, 1);
        border: 1px solid rgba(#e74c3c, 1);
        color: #fff;
      }
      &--loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px !important;
        .loading {
          padding: 0;
        }
      }
    }
    &_in {
      width: 600px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &_form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 15px;
      }
    }
    &_footer {
      text-align: center;
      padding: 0 10px 10px 10px;
      color: #777;
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      width: 350px;
    }
  }
}

@keyframes showSignUpForm {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fromBottom30px {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  .booking_form_title {
    font-size: 22px;
  }
  .booking_form_in_btn {
    width: 95%;
  }
  .booking_form {
    top: 25%;
  }
  .booking_form_footer {
    width: 100%;
  }
  .booking_header button {
    top: 28px;
    right: 10px;
  }
  .booking_form {
    padding: 25px 10px;
    &_in {
      width: 100%;
      &_form {
        padding: 0 5px;
      }
    }
    &_btn {
      width: 100%;
    }
  }
}

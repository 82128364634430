.add_category {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
  width: 100%;
  height: 100%;
  &_in {
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow:
      0 15px 35px rgba(50, 50, 93, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.17);
    flex-direction: column;
    pointer-events: auto;
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 0.4375rem;
    outline: 0;
    background-clip: padding-box;
    padding: 20px 0;
    transform: translateY(-100px);
    &--active {
      animation: showModal 0.3s ease;
      transform: translateY(0);
    }
    .form {
      padding: 20px;
      &_actions {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid rgba(#999, 0.2);
      padding: 20px 30px;
      &_title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        h2 {
          font-family: inherit;
          font-weight: 600;
          line-height: 1.5;
          font-size: 16px;
          color: #25262b;
          text-align: start;
          margin-bottom: 0;
        }
        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
          background-size: cover;
          margin-left: 5px;
        }
      }
      svg {
        transition: all 0.3s ease;
        cursor: pointer;
        font-size: 20px;
        color: #25262b;
        &:hover {
          transform: translateY(-3px);
        }
      }
    }
  }
}

@keyframes showModal {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  .add_category_in {
    width: 95%;
  }
}

.shop_checkout {
  width: 500px;
  height: 100%;
  position: fixed;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  right: -100%;
  bottom: 0;
  z-index: 4;
  opacity: 0;
  flex-direction: column;
  border-left: 1px solid rgba(36, 41, 48, 0.08);
  &--show {
    right: 0;
    z-index: 4;
    opacity: 1;
    animation: showBasket 0.3s ease;
  }
  &_header {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0 10px 10px;
    background: #fff;
    h2 {
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      color: #25262b;
      text-transform: uppercase;
    }
    svg {
      font-size: 20px;
      cursor: pointer;
      margin-right: 5px;
      color: #25262b;
    }
    &_share {
      margin-left: auto;
      &_in {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        margin-right: 10px;
        cursor: pointer;
        color: var(--dynamic-color);
      }
      svg {
        font-size: 18px;
        cursor: pointer;
        margin-right: 10px;
        color: var(--dynamic-color);
        transition: all 0.3s ease;
      }
    }
  }
  &_in {
    width: 100%;
    padding: 0 10px 10px 10px;
    overflow: auto;
    &_title {
      font-size: 16px;
      font-weight: 600;
      color: var(--dynamic-color);
      margin: 10px 0 10px 0;
    }
    &_info {
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 5px 0 0 0;
      flex-wrap: wrap;
      span {
        background: #e8e8e8;
        color: var(--dynamic-color);
        font-size: 13px;
        margin-right: 5px;
        border: none !important;
        transition: all 0.3s !important;
        font-weight: 400 !important;
        padding: 4px 8px !important;
        cursor: default !important;
        border-radius: 12px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        margin-bottom: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &_payment_method {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      background: #fff;
      padding: 5px 12px;
      border-radius: 8px;
      margin-bottom: 10px;
      box-shadow:
        rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
        rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
      cursor: pointer !important;
      transition: all 0.3s ease;
      height: 50px;
      border: 1px solid transparent;
      &--selected {
        background: #f8f9fa !important;
        box-shadow: none !important;
        border: 1px solid rgba(36, 41, 48, 0.08) !important;
        &:hover {
          transform: translateY(0) !important;
          cursor: default !important;
        }
      }
      &:hover {
        transform: translateY(-3px);
      }
      &:last-child {
        margin-bottom: 0;
      }
      &_icon {
        svg {
          font-size: 18px;
          margin-right: 5px;
          color: var(--dynamic-color);
        }
        img {
          width: 40px;
          margin-right: 2px;
        }
      }
      p {
        font-size: 13px;
        font-weight: 300;
        margin-right: auto;
        margin-left: 5px;
      }
      input[type='checkbox'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        display: inline-block;
        width: 18px !important;
        height: 18px !important;
        color: #fff;
        padding: 1px;
        background-clip: content-box;
        border: 2px solid rgb(200, 200, 200);
        background-color: rgb(200, 200, 200);
        border-radius: 100px;
        margin-right: 10px;
      }
      input[type='checkbox']:checked {
        background-color: var(--dynamic-color);
      }
    }
    &_payment_methods {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      &_card {
        width: calc(100% / 3 - 5px);
        margin-bottom: 5px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 12px;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 2px solid transparent;
        background: #fff;
        &:hover {
          transform: translateY(-3px);
        }
        &--active {
          border: 2px solid var(--dynamic-color);
        }
        &_img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            min-height: 50px;
            max-height: 50px;
            background-position: center;
            object-position: center;
            object-fit: contain;
            background-size: contain;
          }
        }
      }
    }
    &_order {
      background: #fff;
      border: 1px solid rgba(36, 41, 48, 0.08);
      width: 100%;
      padding: 5px 10px;
      border-radius: 6px;
      &_guest {
        width: 100%;
        padding: 5px 0 5px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &_btn {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 13px;
          font-weight: 600;
          color: #25262b;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100px;
          span {
            margin-left: 5px;
            color: #25262b;
            list-style: none;
            font-size: 13px;
            font-weight: 300;
          }
        }
        p {
          color: #25262b;
          list-style: none;
          font-size: 13px;
          font-weight: 600;
          margin-left: auto;
        }
      }
    }
    &_bill {
      width: 100%;
      visibility: hidden;
      display: none;
      border-bottom: 1px dashed #868e96;
      padding-bottom: 5px;
      margin-bottom: 10px;
      &--open {
        display: block;
        visibility: visible;
      }
      li {
        display: flex;
        list-style: none;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 13px;
          font-weight: 300;
          color: #25262b;
          padding: 3px 0;
          margin-left: 5px;
        }
        span {
          color: #25262b;
          list-style: none;
          font-size: 13px;
          font-weight: 300;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
      &_subtotal {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin-top: 15px;
        li {
          list-style: none;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 0;
          p {
            font-size: 13px;
            font-weight: 600;
            color: #25262b;
          }
          span {
            color: #25262b;
            list-style: none;
            font-size: 13px;
            font-weight: 600;
          }
        }
      }
      &_total {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #25262b;
        padding-top: 5px;
        margin-top: 5px;
        margin-bottom: 8px;
        p {
          font-size: 13px;
          font-weight: 600;
          color: #25262b;
        }
        span {
          color: #25262b;
          list-style: none;
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
  }
  &_actions {
    width: 100%;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &_btn {
      width: calc(100% - 10px);
      padding: 5px 10px;
      border-radius: 6px;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #25262b !important;
      color: #fff;
      height: 50px !important;
      animation: showBasketBtn 0.3s ease;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      margin: 5px auto 0 auto;
      &--loading {
        background: #fff;
      }
    }
  }
  &_loading {
    width: 80px;
    height: 80px;
    position: fixed;
    top: 50%;
    left: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    z-index: 3;
    &--dark {
      background: transparent !important;
    }
  }
  &_review_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
  }
}

@keyframes showBasket {
  0% {
    opacity: 0;
    z-index: 1;
    right: -100%;
  }
  100% {
    right: 0;
    z-index: 4;
    opacity: 1;
  }
}
@keyframes showRating {
  0% {
    opacity: 0;
    bottom: -100%;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}

@media only screen and (max-width: 500px) {
  .shop_checkout {
    width: 100%;
  }
}

.terminal_category_product_details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 550px;
  background: #fff;
  border-radius: 10px;
  box-shadow:
    0 15px 35px rgba(50, 50, 93, 0.2),
    0 5px 15px rgba(0, 0, 0, 0.17);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100px);
  opacity: 1;
  z-index: 12;
  padding: 20px 0;
  &--show {
    animation: showTerminalPopUp 0.3s ease;
    transform: translate(-50%, -50%);
  }
  &_in {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 20px 30px;
    &_name {
      font-size: 18px;
      width: 100%;
      font-weight: 500;
      color: #25262b;
      padding-bottom: 15px;
    }
    &_description {
      font-size: 14px;
      font-weight: 300;
      color: #25262b;
      width: 100%;
      padding-bottom: 15px;
    }
    &_price {
      font-size: 14px;
      font-weight: 400;
      color: #25262b;
      width: 100%;
    }
  }
  &_extras {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0 30px;
    flex-direction: column;
    &_extra {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 0;
      width: 100%;
      cursor: pointer;
      &_label {
        border-radius: 100px;
        border: 1px solid #25262b;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;
        span {
          background: none;
          width: 14px;
          height: 14px;
          border-radius: 100px;
        }
        &--active {
          span {
            background: #25262b;
          }
        }
      }
      input[type='checkbox'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        display: inline-block;
        width: 18px !important;
        height: 18px !important;
        color: #fff;
        padding: 1px;
        background-clip: content-box;
        border: 2px solid rgb(200, 200, 200);
        background-color: rgb(200, 200, 200);
        border-radius: 100px;
      }
      input[type='checkbox']:checked {
        background-color: #25262b;
      }
      &_name {
        font-size: 14px;
        font-weight: 400;
        color: #25262b;
        margin-left: 10px;
        text-align: left;
      }
      &_price {
        font-size: 14px;
        font-weight: 400;
        color: #25262b;
        margin-left: auto;
        text-align: left;
      }
    }
  }
  &_footer {
    margin-top: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    & button {
      width: 80%;
      min-width: 200px;
      cursor: pointer;
      background: #25262b;
      padding: 5px 10px;
      border-radius: 6px;
      border: none;
      transition: all 0.3s ease;
      color: #fff;
      height: 40px;
      font-size: 14px;
      font-weight: 600;
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
  &_overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
  }
}

@keyframes showTerminalPopUp {
  0% {
    transform: translate(-50%, -100px);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 600px) {
  .terminal_category_product_details {
    width: 90%;
  }
}

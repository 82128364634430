.live_orders {
  &_switcher {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    background: #f8f9fa;
    border-radius: 6px;
    &_btn {
      text-decoration: none;
      background: #f8f9fa;
      border: 1px solid #ced4db;
      color: #25262b;
      transition: all 0.3s;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 10px;
      cursor: pointer;
      border-radius: 6px;
      letter-spacing: 0.055em;
      margin-left: 5px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        transform: translateY(-3px);
      }
      &--active {
        color: #fff;
        background: #25262b;
        border: 1px solid #25262b;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .live_orders_switcher {
    margin-left: auto;
  }
}

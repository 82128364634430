.add_category_product {
  position: fixed;
  top: 0;
  right: 0;
  width: 550px;
  height: 100%;
  z-index: 3;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateX(550px);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .form {
    padding: 20px 15px;
  }
  &--active {
    animation: openDetailsPage 0.5s ease;
    transform: translateX(0);
  }
  &_in {
    width: 100%;
    overflow: auto;
    height: calc(100% - 80px);
    padding-top: 20px;
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 0 15px 0 15px;
      img {
        width: 18px;
        height: 18px;
        object-fit: fill;
        background-position: center;
        background-size: cover;
      }
    }
    &_table {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &_label {
        background-color: rgba(203, 210, 246, 0.5);
        color: #25262b;
        border: none;
        transition: all 0.3s;
        font-size: 12px;
        font-weight: 600;
        padding: 6px 12px;
        height: 30px;
        border-radius: 6px;
        letter-spacing: 0.055em;
        margin: 0 0 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        font-size: 16px !important;
        margin-left: 8px !important;
        color: #25262b;
        transition: all 0.3s ease;
        cursor: pointer !important;
        &:hover {
          color: #25262b;
        }
      }
    }
    &_title {
      font-size: 16px;
      font-weight: 600;
      color: #25262b;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 5px;
    }
    &_name {
      font-size: 16px;
      font-weight: 600;
      color: #25262b;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 15px 0 15px;
    }
  }
  &_footer {
    width: 100%;
    height: 80px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    &_btn {
      font-size: 14px;
      font-weight: 400 !important;
      transition: all 0.3s ease;
      cursor: pointer;
      padding: 6px 12px;
      letter-spacing: 0.055em;
      border-radius: 6px;
      &--cancel {
        background: #fff;
        border: 1px solid rgba(#25262b, 0);
        color: #25262b;
        font-weight: 400;
        &:hover {
          transform: translateY(-3px);
        }
      }
      &--submit {
        background: rgba(#25262b, 1);
        border: 1px solid rgba(#25262b, 0);
        font-weight: 600;
        margin-left: 20px;
        box-shadow:
          0 4px 6px rgba(50, 50, 93, 0.11),
          0 1px 3px rgba(0, 0, 0, 0.1);
        color: #fff;
        &:hover {
          transform: translateY(-3px);
        }
      }
    }
  }
}

@keyframes openDetailsPage {
  0% {
    transform: translateX(550px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes openDetailsPageMobile {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 600px) {
  .add_category_product {
    width: 100%;
    transform: translateX(100%);
    &--active {
      animation: openDetailsPageMobile 0.5s ease;
      transform: translateX(0);
    }
  }
}

.category_product {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 3px 0;
  text-decoration: none;
  transition: all 0.3s ease;
  &--highlighted {
    transform: scale(0.9);
    background: transparent;
    .category_product_in {
      background: #f3f3f4;
    }
  }
  &--dragging {
    opacity: 0.5;
    background: transparent;
    .category_product_in {
      background: #f3f3f4;
    }
  }
  &_reorder {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 20px;
      color: #25262b;
      transition: all 0.3s ease;
    }
  }
  &_in {
    padding: 2px 10px 2px 10px;
    width: 100%;
    border: 1px solid rgba(#8898aa, 0.15);
    transition: all 0.3s ease;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fff;
    &--freezed {
      background: #f5f5f5;
    }
    &:hover {
      background: #f3f3f4;
    }
    &:-moz-drag-over {
      background: #f3f3f4;
    }
    &_wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &_name {
      color: #25262b;
      font-weight: 400;
      line-height: 1.5;
      font-size: 14px;
      margin: 5px 0 5px 0;
      display: inline;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 700px;
    }
    &_price {
      color: #25262b;
      border: none;
      transition: all 0.3s;
      font-size: 13px;
      font-weight: 400;
      padding: 4px 8px;
      height: 30px;
      border-radius: 6px;
      letter-spacing: 0.055em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_status {
      cursor: pointer;
      border: none;
      transition: all 0.3s;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 600;
      padding: 4px 10px;
      border-radius: 4px;
      letter-spacing: 0.055em;
      display: flex;
      align-items: center;
      justify-content: center;
      &--active {
        background: #d8fbe7;
        color: #255037;
      }
      &--disable {
        background: rgba(#e74c3c, 0.1);
        color: #e74c3c;
      }
    }
  }
}

.drop_down {
  width: 100%;
  height: 100%;
  position: relative;
  &_in {
    width: 100%;
    height: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cad1d7;
    transition: all 0.3s;
    border-radius: 6px;
    overflow: hidden;
    padding: 0 15px;
    &:hover {
      border: 1px solid #cad1d7;
    }
    &_disable_hover {
      &:hover {
        border: 1px solid #cad1d7;
      }
    }
    &_pagination {
      border: none !important;
      padding: 0 !important;
      .drop_down_search {
        .drop_down_input {
          text-align: center;
          color: #25262b !important;
          font-weight: 400;
        }
      }
    }
    &_lng {
      min-height: 35px;
      border: 1px solid #cad1d7;
      padding: 0 8px;
      input {
        color: #25262b;
        font-size: 14px !important;
        font-weight: 300 !important;
      }
    }
  }
  &_input {
    width: 100%;
    height: 100%;
    font-size: 15px;
    border: none !important;
    cursor: pointer;
    padding: 0 !important;
    &--active {
      z-index: 6;
    }
    &:focus {
      outline: none;
      cursor: text;
      border: none;
    }
    &:hover {
      border: none;
    }
  }
  &_label {
    padding: 0 5px 0 0 !important;
    height: 100% !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 5;
    &_pagination {
      display: none;
    }
    & svg {
      color: #aaa;
      font-size: 2rem;
      cursor: pointer;
      background: transparent;
      margin: 0 !important;
      margin-left: auto !important;
      padding: 0 !important;
    }
  }
  &_img {
    width: 25px;
    height: 25px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
    img {
      width: 100% !important;
      background-size: cover;
      object-fit: cover;
      margin: 0 !important;
    }
  }
  &_menu {
    width: 100%;
    max-width: 200px;
    max-height: 155px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #fff;
    color: #25262b;
    padding: 0;
    border: 1px solid #cad1d7;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    top: 35px;
    left: 0;
    z-index: 8;
    &__item {
      &--active {
        background: #f3f3f4;
        color: #25262b !important;
        & svg {
          color: #25262b !important;
        }
      }
    }
    & p {
      font-size: 14px;
      font-weight: 400;
      color: #25262b;
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 10px;
      cursor: default;
      margin-top: 0;
      margin-bottom: 0 !important;
    }
    & ul {
      text-decoration: none;
      list-style: none;
      width: 100%;
      & li {
        font-size: 14px;
        font-weight: 400;
        width: 100%;
        height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px 10px;
        transition: all 0.3s;
        color: #25262b;
        &:hover {
          background: #f3f3f4;
          color: #25262b !important;
          & svg {
            color: #25262b;
          }
        }
      }
    }
    &_pagination {
      ul {
        li {
          justify-content: center !important;
        }
      }
    }
  }
  &_search {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &_overlay {
    width: 100%;
    height: 100%;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7;
  }
  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

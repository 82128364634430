.tables {
  &_warning {
    display: flex;
    align-items: center;
    justify-content: center;
    &_btn {
      border: 1px solid #25262b;
      background: #25262b;
      color: #fff;
      transition: all 0.3s;
      font-size: 12px;
      font-weight: 400;
      padding: 8px 10px;
      cursor: pointer;
      border-radius: 6px;
      letter-spacing: 0.055em;
      width: 200px;
      margin: 20px 0;
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
}

.wrapper {
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 280px);
  margin-left: auto;
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    width: 100%;
  }
}

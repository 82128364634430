.payment_methods {
  &_list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    list-style: none;
    flex-wrap: wrap;
    &_item {
      width: 100%;
      min-height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 10px;
      padding: 0 10px;
      border-radius: 12px;
      &_img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 60px;
          height: 60px;
          object-fit: contain;
          background-size: cover;
          background-position: center;
          object-position: center;
          padding: 2px;
          border-radius: 6px;
        }
        h3 {
          padding: 0 10px;
          font-size: 16px;
          font-weight: 600;
          color: #25262b;
        }
      }
      &_in {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        &_btn {
          font-size: 12px;
          line-height: 1.5;
          padding: 5px 10px;
          border-radius: 6px;
          color: #fff;
          box-shadow:
            0 4px 6px rgba(50, 50, 93, 0.11),
            0 1px 3px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          letter-spacing: 0.055em;
          justify-content: center;
          font-weight: 400;
          &--add {
            background: rgba(#8db864, 1);
            border: 1px solid rgba(#8db864, 1);
            &:hover {
              transform: translateY(-3px);
              background: rgba(#8db864, 1);
              border: 1px solid rgba(#8db864, 1);
            }
          }
          &--delete {
            background: rgba(#e74c3c, 1);
            border: 1px solid rgba(#e74c3c, 1);
            &:hover {
              transform: translateY(-3px);
              background: rgba(#e74c3c, 1);
              border: 1px solid rgba(#e74c3c, 1);
            }
          }
          &--edit {
            background: rgba(#25262b, 1);
            border: 1px solid rgba(#25262b, 1);
            margin-left: 30px;
            &:hover {
              transform: translateY(-3px);
              background: rgba(#25262b, 1);
              border: 1px solid rgba(#25262b, 1);
            }
          }
        }
        &_switcher {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          & input[type='checkbox'] {
            -webkit-appearance: none;
            background: #f8f9fa;
            width: 60px;
            padding: 0 !important;
            height: 25px;
            border: none !important;
            border-radius: 20px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            transition: all 0.3s;
            position: relative;
            left: 0;
            cursor: pointer;
          }
          & input:checked[type='checkbox'] {
            background: #f8f9fa;
          }
          & input[type='checkbox']:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background: rgba(#999, 0.5);
            transition: all 0.5s ease;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            margin-left: 3px;
          }
          & input:checked[type='checkbox']::before {
            left: 34px;
            background: #25262b;
          }
          & input[type='checkbox'] {
            -webkit-appearance: none;
            background: #f8f9fa;
            width: 60px;
            padding: 0 !important;
            height: 25px;
            border: none !important;
            border-radius: 20px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            transition: all 0.3s;
            position: relative;
            left: 0;
            cursor: pointer;
          }
          & input:checked[type='checkbox'] {
            background: #f8f9fa;
          }
          & input[type='checkbox']:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background: rgba(#999, 0.5);
            transition: all 0.5s ease;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            margin-left: 3px;
          }
          & input:checked[type='checkbox']::before {
            left: 34px;
            background: #25262b;
          }
        }
      }
    }
  }
  &_modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
    width: 100%;
    height: 100%;
    &_in {
      width: 550px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      box-shadow:
        0 15px 35px rgba(50, 50, 93, 0.2),
        0 5px 15px rgba(0, 0, 0, 0.17);
      flex-direction: column;
      pointer-events: auto;
      border: 0 solid rgba(0, 0, 0, 0.2);
      border-radius: 0.4375rem;
      outline: 0;
      background-clip: padding-box;
      padding: 20px 0;
      transform: translateY(-100px);
      &--active {
        animation: showModal 0.3s ease;
        transform: translateY(0);
      }
      .form {
        padding: 20px;
        &_actions {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid rgba(#999, 0.2);
        padding: 20px 30px 20px 15px;
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          object-fit: contain;
          background-size: cover;
          background-position: center;
          object-position: center;
          padding: 2px;
          border-radius: 6px;
        }
        h2 {
          font-family: inherit;
          font-weight: 600;
          line-height: 1.5;
          font-size: 16px;
          color: #25262b;
          width: 100%;
          text-align: start;
        }
        i {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        svg {
          transition: all 0.3s ease;
          cursor: pointer;
          font-size: 20px;
          color: #25262b;
          &:hover {
            transform: translateY(-3px);
          }
        }
      }
    }
  }
}

@keyframes showModal {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  .payment_methods_modal_in {
    width: 95%;
  }
  .payment_methods_list {
    padding: 0 10px 20px 10px;
  }
  .payment_methods_list_item_img h3 {
    font-size: 14px;
  }
  .payment_methods_list_item_in_btn {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 450px) {
  .payment_methods_list_item_img h3 {
    display: none;
  }
}

.shop_home {
  width: 100%;
  height: 100%;
  &--short {
    width: calc(100% - 500px);
  }
  &_loading {
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.terminal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
  width: 100%;
  height: 100%;
  &_in {
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 1;
    border: 0 solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    transform: translateY(0);
    overflow: auto;
    .shop_categories {
      background: #f8f9fa;
    }
    .shop_header_in_search_modal_in {
      background: #f8f9fa;
    }
    &--orders {
      background: #f8f9fa !important;
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid rgba(#999, 0.2);
      padding: 0 15px;
      background: #fff !important;
      &_back {
        margin-right: 10px;
        font-size: 13px;
        color: #25262b;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          color: #25262b;
        }
      }
      h2 {
        font-family: inherit;
        font-weight: 600;
        font-size: 14px;
        color: #25262b;
        display: inline;
        text-align: left;
        margin-left: 5px;
      }
      svg {
        transition: all 0.3s ease;
        cursor: pointer;
        font-size: 20px;
        color: #25262b;
        &:hover {
          transform: translateY(-3px);
        }
      }
    }
    &_body {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: calc(100% - 60px);
      overflow: auto;
      &_tables {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        &_search {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0 0 0 10px;
          background: #fff;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          &_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 5px;
            svg {
              font-size: 20px;
              color: #999;
            }
          }
          & input {
            display: block;
            width: calc(100% - 40px);
            height: 50px;
            color: #25262b;
            border: none;
            padding: 15px 0;
            background-color: #fff;
            background-clip: padding-box;
            box-shadow: none;
            font-size: 15px;
            transition: all 0.3s ease;
            &:focus,
            &:focus-visible,
            &:hover {
              border: none;
              outline: none;
            }
          }
        }
        &_in {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding: 10px 15px 0 15px;
          &_item {
            width: 120px;
            height: 120px;
            border-radius: 6px;
            box-shadow:
              rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
              rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            background: #f8f9fa;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-right: 5px;
            margin-bottom: 10px;
            cursor: pointer;
            transition: all 0.3s ease;
            &:last-child {
              margin-right: 0;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #25262b;
              padding: 5px;
              overflow-wrap: anywhere;
              text-align: center;
            }
            &--busy {
              background: #25262b;
              color: #fff;
              p {
                color: #fff !important;
              }
            }
            &:hover {
              transform: translateY(-3px);
              transform: scale(0.99);
            }
          }
        }
      }
      &_order {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 60px);
        &_btn {
          background: #25262b;
          color: #fff;
          border: none;
          width: 200px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          height: 200px;
          font-size: 20px;
          border-radius: 16px;
          font-weight: 600;
          margin: 0 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          transition: all 0.3s ease;
          &:hover {
            transform: scale(1.05);
            background: #f0b101;
            color: #000;
          }
        }
      }
      &_list {
        width: 80%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        overflow-y: auto;
        &--short {
          width: 35%;
        }
        &_loading {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &_loading {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &_item {
        width: 100%;
        list-style: none;
        background: transparent;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 60px;
        font-size: 15px;
        font-weight: 400;
        cursor: pointer;
        transition: all 0.3s ease;
        border-bottom: 1px solid rgba(#999, 0.2);
        color: #000;
        &_wrapper {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          flex-wrap: wrap;
        }
        &_options {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          p {
            font-size: 14px;
            font-weight: 400;
            padding: 3px 0;
          }
          span {
            margin-right: 10px;
            border: none;
            background-color: rgba(203, 210, 246, 0.5);
            color: #5e72e4;
            transition: all 0.3s;
            font-size: 13px;
            font-weight: 400;
            padding: 6px 10px;
            cursor: pointer;
            border-radius: 16px;
            letter-spacing: 0.055em;
          }
        }
        &_actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-left: auto;
          height: 100%;
          span {
            font-size: 13px;
            font-weight: 400;
            margin: 0 8px;
          }
          &_btn {
            border: none;
            width: 30px;
            height: 30px;
            font-size: 12px;
            border-radius: 100px;
            font-weight: 600;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            &--add {
              background-color: rgba(203, 210, 246, 0.5);
              svg {
                font-size: 20px;
                path {
                  stroke: #25262b;
                }
              }
            }
            &--remove {
              background: rgba(#e74c3c, 1);
              svg {
                font-size: 20px;
                color: #fff;
              }
            }
          }
        }
        p {
          font-size: 14px;
          font-weight: 400;
          padding: 3px 0;
          margin-right: 10px;
        }
      }
      &_overlay {
        position: fixed;
        z-index: 4;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
      }
      .no_data {
        border-top: none;
      }
    }
  }
  .shop_basket_btn {
    width: 90%;
    margin: 0 auto;
  }
  .shop_categories_products {
    height: calc(100% - 60px - 40px - 50px);
  }
}

@media only screen and (max-width: 600px) {
  .terminal_in_header h2 {
    font-size: 14px;
  }
  .terminal_in_body_tables_search {
    padding: 0 0 0 5px;
  }
  .terminal_in_body_list {
    width: 100%;
    margin-top: 60px;
    height: calc(100% - 60px);
  }
}
@media only screen and (max-width: 500px) {
  .terminal_in_header {
    padding: 0 10px;
    .staff_in_header_btn {
      margin-left: 0;
    }
  }
  .terminal_basket_btn {
    margin-left: auto;
  }
  .terminal_in_body_tables_in_item {
    width: calc(50% - 5px);
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  .terminal_in_body_tables_in {
    padding: 10px 5px 0 5px;
  }
}

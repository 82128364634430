.category_product_details {
  position: fixed;
  top: 0;
  right: 0;
  width: 550px;
  height: 100%;
  z-index: 3;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateX(550px);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  &--active {
    animation: openDetailsPage 0.5s ease;
    transform: translateX(0);
  }
  &_in {
    width: 100%;
    overflow: auto;
    height: calc(100% - 80px);
    padding: 20px 0 0 0;
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 0 15px;
      img {
        width: 18px;
        height: 18px;
        object-fit: fill;
        background-position: center;
        background-size: cover;
      }
    }
    &_table {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &_selection {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        padding: 0 15px;
        &:hover {
          p,
          svg {
            color: #25262b;
          }
        }
        p {
          transition: all 0.3s ease;
          font-size: 14px;
          color: #25262b;
          font-weight: 400;
        }
        svg {
          transition: all 0.3s ease;
          color: #25262b;
          font-size: 14px;
        }
      }
      &_label {
        border: none;
        transition: all 0.3s;
        height: 30px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #25262b;
          letter-spacing: 0.055em;
          text-align: left;
        }
        svg {
          width: 20px;
          font-size: 16px !important;
          margin-left: 5px !important;
          color: #25262b;
          transition: all 0.3s ease;
          cursor: pointer !important;
          &:hover {
            color: #25262b;
          }
        }
      }
    }
    &_title {
      font-size: 16px;
      font-weight: 600;
      color: #25262b;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 5px;
    }
    &_name {
      font-size: 16px;
      font-weight: 600;
      color: #25262b;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 15px 0 15px;
    }
    &_btn {
      font-size: 12px;
      line-height: 1.5;
      padding: 5px 10px;
      border-radius: 6px;
      color: #fff;
      box-shadow:
        0 4px 6px rgba(50, 50, 93, 0.11),
        0 1px 3px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      letter-spacing: 0.055em;
      justify-content: center;
      font-weight: 600;
      margin-left: auto;
      background: rgba(#25262b, 1);
      border: 1px solid rgba(#25262b, 0);
      &:hover {
        transform: translateY(-3px);
        background: rgba(#25262b, 1);
        border: 1px solid rgba(#25262b, 1);
      }
    }
  }
  &_footer {
    width: 100%;
    height: 80px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &_btn {
      font-size: 14px;
      line-height: 1.5;
      padding: 5px 10px;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      letter-spacing: 0.055em;
      justify-content: center;
      &--cancel {
        background: #fff;
        border: none;
        color: #25262b;
        text-transform: lowercase;
        font-weight: 400;
        &:hover {
          transform: translateY(-3px);
        }
      }
      &--submit {
        background: rgba(#25262b, 1);
        border: 1px solid rgba(#25262b, 0);
        font-weight: 600;
        margin-left: 20px;
        box-shadow:
          0 4px 6px rgba(50, 50, 93, 0.11),
          0 1px 3px rgba(0, 0, 0, 0.1);
        color: #fff;
        &:hover {
          transform: translateY(-3px);
        }
      }
    }
  }
  .form {
    padding: 20px 15px;
    &_column {
      margin-right: 0;
    }
  }
}

@keyframes openDetailsPage {
  0% {
    transform: translateX(550px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes openDetailsPageMobile {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 600px) {
  .category_product_details {
    width: 100%;
    transform: translateX(100%);
    &--active {
      animation: openDetailsPageMobile 0.5s ease;
      transform: translateX(0);
    }
  }
}

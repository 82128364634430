.table {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  border-collapse: collapse;
  &_header {
    width: 100%;
    height: 40px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    background: transparent;
    tr {
      background: transparent;
    }
    th {
      background: #fff;
      font-size: 14px;
      text-transform: capitalize;
      text-align: left;
      padding-left: 15px;
      font-weight: 600;
      color: #25262b;
      height: 40px;
      svg {
        font-size: 16px;
        cursor: pointer;
        justify-content: center;
        margin-bottom: -4px;
        padding: 1px;
        color: #999;
        margin-left: 5px;
      }
    }
  }
  &_body {
    width: 100%;
    tr {
      background-color: #fff;
      color: #333;
      border-bottom: 1px solid #f8f9fa;
      & svg {
        font-size: 14px;
      }
    }
    tr.table--has_route {
      transition: all 0.3s ease;
      td > * {
        cursor: pointer;
        transition: all 0.3s ease;
      }
      &:hover {
        cursor: pointer;
        background: rgba(#f3f3f4, 1);
      }
    }
    td {
      position: relative;
      & p {
        min-height: 45px;
        cursor: default;
        display: flex;
        align-items: center;
        padding-left: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #25262b;
        display: flex;
        justify-content: flex-start;
        span {
          margin-left: 5px;
          border: none;
          transition: all 0.3s;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 600;
          padding: 2px 10px;
          border-radius: 4px;
          letter-spacing: 0.055em;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(#e74c3c, 0.1);
          color: #e74c3c;
        }
      }
    }
  }
}

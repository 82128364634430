.orders_list {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
  width: 100%;
  height: 100%;
  &_in {
    width: 600px;
    max-height: 90%;
    min-height: 10%;
    overflow: auto;
    box-shadow:
      0 15px 35px rgba(50, 50, 93, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.17);
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    outline: 0;
    background-clip: padding-box;
    padding: 10px 0 20px 0;
    transform: translateY(-100px);
    background: #fff;
    &--active {
      animation: showModal 0.3s ease;
      transform: translateY(0);
    }
  }
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(#525f7f, 0.3);
    padding: 10px 20px;
    height: 40px;
    button {
      margin-left: 0;
      cursor: default;
    }
    h2 {
      font-family: inherit;
      font-weight: 400;
      line-height: 1.5;
      font-size: 13px;
      color: #25262b;
      width: 100%;
      text-align: start;
      margin-left: 5px;
    }
    svg {
      transition: all 0.3s ease;
      cursor: pointer;
      font-size: 20px;
      color: #25262b;
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
  &_details {
    width: 100%;
    height: calc(100% - 40px);
    overflow: auto;
    &_in {
      width: 100%;
      padding: 0 20px;
      &_title {
        font-size: 16px;
        font-weight: 600;
        color: #25262b;
        margin: 0 0 5px 0;
        &--light {
          font-weight: 300 !important;
        }
      }
      &_info {
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 5px 0 5px 0;
        flex-wrap: wrap;
        &_wrapper {
          width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
        }
        li {
          width: 100%;
          color: #25262b;
          list-style: none;
          font-size: 14px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 6px 0;
          &:last-child {
            padding: 2px 0;
          }
          span {
            color: #25262b;
            list-style: none;
            font-size: 12px;
            font-weight: 400;
            margin-right: 10px;
          }
          button {
            padding: 4px 12px;
          }
        }
      }
      &_bill {
        &_order {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          width: 100%;
          &_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            h3 {
              font-size: 12px;
              font-weight: 400;
              color: #fff;
              border: 1px solid #25262b;
              background: #25262b;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 4px 10px;
              border-radius: 6px;
              transition: all 0.3s ease;
              svg {
                color: #fff;
                font-size: 14px;
                transition: all 0.3s ease;
                margin-right: 5px;
              }
            }
            p {
              font-size: 13px;
              font-weight: 300;
              color: #25262b;
              padding: 3px 0;
            }
            span {
              color: #25262b;
              list-style: none;
              font-size: 13px;
              font-weight: 300;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;
            }
          }
          &_list {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
        }
        &_subtotal {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          margin-top: 15px;
          li {
            list-style: none;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 4px 0;
            p {
              font-size: 13px;
              font-weight: 600;
              color: #25262b;
            }
            span {
              color: #25262b;
              list-style: none;
              font-size: 13px;
              font-weight: 600;
            }
          }
        }
        &_total {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #25262b;
          padding-top: 5px;
          margin-top: 5px;
          margin-bottom: 8px;
          p {
            font-size: 13px;
            font-weight: 600;
            color: #25262b;
          }
          span {
            color: #25262b;
            list-style: none;
            font-size: 13px;
            font-weight: 600;
          }
        }
      }
    }
    &_actions {
      width: 100%;
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &_btn {
        border: 1px solid #25262b;
        background: #25262b;
        color: #fff;
        transition: all 0.3s;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.055em;
        padding: 12px 10px;
        cursor: pointer;
        border-radius: 6px;
        width: 200px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 16px -1px rgba(0, 0, 0, 0.2);
        &:hover {
          transform: translateY(-3px);
        }
      }
    }
  }
}

@keyframes showModal {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 800px) {
  .orders_list_in {
    width: 95%;
  }
}
@media only screen and (max-width: 600px) {
  .orders_list_details_in_info {
    padding: 8px 15px;
    flex-wrap: wrap;
  }
  .orders_list_details_in_info_wrapper {
    width: 100%;
  }
  .orders_list_details_in_info li {
    font-size: 14px;
    span {
      font-size: 12px;
    }
  }
  .orders_list_details_in_bill_order_wrapper {
    flex-wrap: wrap;
  }
}

.category {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px 0 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(36, 41, 48, 0.08);
  &--highlighted {
    background: #f8f9fa;
    transform: scale(0.9);
  }
  &--dragging {
    opacity: 0.5;
    background: transparent;
  }
  &:hover {
    .category_header_name {
      color: #25262b;
    }
  }
  &_header {
    width: 100%;
    background-color: #fff;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &_reorder {
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 20px;
        color: #25262b;
        transition: all 0.3s ease;
      }
    }
    &_name {
      font-size: 16px;
      font-weight: 600;
      color: #25262b;
      transition: all 0.3s ease;
      display: inline;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 700px;
      &_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        svg {
          font-size: 20px;
        }
        &--show {
          svg {
            color: #07bc0c;
          }
        }
        &--hide {
          svg {
            color: #e74c3c;
          }
        }
      }
    }
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
    }
    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      height: 34px;
      width: 34px;
      border-radius: 100px;
      background: #f8f9fa;
      svg {
        font-size: 16px;
        color: #25262b;
        transition: all 0.3s ease;
      }
      &:hover {
        background: #25262b;
        svg {
          color: #fff;
          transform: scale(1.1);
        }
      }
    }
  }
  &_content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    &_loadmore {
      margin: 0 auto;
      background: none;
      padding: 8px 0;
      color: #25262b;
      font-weight: 400;
      font-size: 13px;
      transition: all 0.3s ease;
      border: none;
      text-transform: capitalize;
      cursor: pointer;
      &:hover {
        transform: translateY(-3px);
      }
    }
    &_empty {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 0 10px 0;
      &_txt {
        width: 100%;
        padding-bottom: 10px;
        text-align: center;
        font-size: 14px;
        color: #25262b;
        font-weight: 400;
      }
      &_btn {
        font-size: 12px;
        line-height: 1.5;
        padding: 5px 10px;
        border-radius: 6px;
        color: #fff;
        box-shadow:
          0 4px 6px rgba(50, 50, 93, 0.11),
          0 1px 3px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        letter-spacing: 0.055em;
        justify-content: center;
        font-weight: 600;
        background: rgba(#25262b, 1);
        border: 1px solid rgba(#25262b, 0);
        &:hover {
          transform: translateY(-3px);
          background: rgba(#25262b, 1);
          border: 1px solid rgba(#25262b, 1);
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .category {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 500px) {
  .category_header_name {
    font-size: 14px;
  }
}

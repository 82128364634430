.no_data {
  width: 100%;
  padding: 20px 0;
  background: transparent;
  h2 {
    font-size: 14px;
    padding: 0 10px;
    font-weight: 400;
    color: #25262b;
    line-height: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .no_data {
    padding: 20px 10px;
    h2 {
      padding: 0;
      font-size: 14px !important;
    }
  }
}

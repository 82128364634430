.shop_category_product_suggestion {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 10px;
  &--dark {
    .shop_category_product_suggestion_card {
      border: 1px solid rgba(153, 153, 153, 0.2) !important;
    }
    .shop_category_product_suggestion_card_price {
      color: #fff !important;
    }
    .shop_category_product_suggestion_card_name {
      color: #999 !important;
    }
  }
  &_card {
    width: calc(100% / 3 - 10px);
    margin: 5px 0 5px 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 6px;
    padding: 6px;
    background: rgba(#999, 0.08);
    max-height: 210px;
    min-height: 210px;
    &_img {
      width: 100%;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 5px;
      display: flex;
      img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        background-position: center;
        object-position: top;
      }
    }
    &_price {
      font-size: 12px;
      color: var(--dynamic-color);
      font-weight: 400;
      margin: 10px 0 5px 0;
    }
    &_name {
      font-size: 12px;
      color: var(--dynamic-color);
      font-weight: 300;
      margin-bottom: 5px;
    }
    &_btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--dynamic-color);
      border-radius: 100px;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
      padding: 3px 0;
      svg {
        color: #fff;
        path {
          stroke: #fff;
        }
        font-size: 20px;
      }
    }
  }
}

.shop_checkout_button {
  width: calc(100% - 40px);
  height: 50px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: translate(0, 100px);
  opacity: 0;
  margin-right: 0;
  &--show {
    opacity: 1;
    transform: translate(0, 0);
    animation: showBasketBtn 0.3s ease;
  }
  &_in {
    width: 200px;
    height: 100%;
    background: var(--dynamic-color);
    box-shadow: 0px 0px 16px -1px rgba(0, 0, 0, 0.2);
    padding: 5px 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &--dark {
      background: #fff;
    }
    p {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
    }
  }
}

@keyframes showBasketBtn {
  0% {
    opacity: 0;
    transform: translate(0, 100px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media only screen and (max-width: 900px) {
  .shop_checkout_button {
    width: 30%;
    margin-right: 10px;
  }
}

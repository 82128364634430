:root {
  --dynamic-color: #25262b;
}

.shop_passcode {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: #f8f9fa;
  &_in {
    width: 600px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 0 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    position: relative;
  }
  &_form {
    width: 500px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &_img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      img {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        object-fit: contain;
        object-position: center;
      }
    }
    &_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      p {
        font-size: 12px;
        //color: var(--dynamic-color);
        color: var(--dynamic-color);
        text-align: center;
        font-weight: 300;
        margin: 0 auto;
        width: 100%;
        text-transform: lowercase;
        a {
          font-size: 12px;
          color: var(--dynamic-color);
          text-align: center;
          font-weight: 600;
          text-transform: lowercase;
          text-decoration: none;
          transition: all 0.3s ease;
          cursor: pointer;
          transition: all 0.3s ease;
        }
      }
    }
    &_title {
      width: 100%;
      font-size: 21px !important;
      font-weight: 400 !important;
      color: var(--dynamic-color) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 30px 0 !important;
      position: relative;
      z-index: 2;
      text-transform: capitalize !important;
    }
    &_in {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: transparent;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
      &_loading {
        display: flex;
        align-items: center;
        justify-content: center;
        .loading {
          padding: 0 !important;
          height: 70px;
        }
      }
    }
    &_call {
      margin-top: 25px;
      display: flex;
      align-content: center;
      justify-content: flex-start;
      height: 20px;
      p {
        font-size: 14px;
        color: var(--dynamic-color);
        font-weight: 300;
        height: 20px;
        display: flex;
        align-items: center;
      }
      button {
        background: none;
        border: none;
        margin-left: 5px;
        color: var(--dynamic-color);
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        transition: all 0.3s ease !important;
        cursor: pointer;
      }
    }
    &_control {
      display: block;
      height: 50px;
      text-align: center;
      font-size: 45px;
      font-weight: 800;
      width: 25%;
      border: none;
      background: rgba(#999, 0.2);
      box-shadow:
        rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 6px;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-right: 10px;
      color: var(--dynamic-color);
      &:last-child {
        margin-right: 0;
      }
    }
    &_error {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      margin: 10px 0;
      min-height: 20px;
      &_name {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 13px !important;
          color: #e74c3c !important;
          text-align: center;
          font-weight: 300 !important;
          margin: 0 !important;
          width: calc(100% - 25px);
        }
        svg {
          font-size: 18px;
          color: #e74c3c;
          width: 20px;
          margin-right: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .shop_passcode_in {
    width: 95%;
  }
  .shop_passcode_form_title {
    font-size: 18px !important;
  }
  .shop_passcode_form_control {
    height: 60px;
  }
  .shop_passcode_form {
    width: 100%;
  }
}

.integrations {
  &_form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    width: 550px;
    &_in {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      box-shadow:
        0 15px 35px rgba(50, 50, 93, 0.2),
        0 5px 15px rgba(0, 0, 0, 0.17);
      flex-direction: column;
      pointer-events: auto;
      border: 0 solid rgba(0, 0, 0, 0.2);
      border-radius: 0.4375rem;
      outline: 0;
      background-clip: padding-box;
      transform: translateY(-100px);
      overflow: auto;
      padding: 0 15px;
      &--active {
        animation: showModal 0.3s ease;
        transform: translateY(0);
      }
    }
  }
  &_list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    flex-wrap: wrap;
    &_card {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
      background: #fff;
      padding: 10px 0;
      border-bottom: 1px solid #ebedf5;
      svg {
        border: 1px solid #25262b;
        border-radius: 100px;
        width: 60px;
        height: 60px;
        padding: 5px;
      }
      &_img {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 100px;
        width: 60px;
        height: 60px;
        img {
          width: 60px;
          height: 60px;
          background-position: center;
          object-fit: cover;
          background-size: cover;
        }
      }
      &_wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: calc(100% - 60px);
        padding-left: 20px;
        &_in {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          p {
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            color: #25262b;
            text-transform: capitalize;
          }
          button {
            border: 1px solid #25262b;
            font-size: 14px;
            font-weight: 400;
            transition: all 0.3s ease;
            cursor: pointer;
            padding: 6px 12px;
            background: #25262b;
            color: #fff;
            letter-spacing: 0.055em;
            border-radius: 6px;
            &:hover {
              transform: translateY(-3px);
            }
          }
        }
        a {
          font-size: 14px;
          font-weight: 300;
          text-align: left;
          color: #25262b;
          text-transform: lowercase;
          transition: all 0.3s ease;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &_contacts {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    &_in {
      padding: 10px;
      box-shadow:
        rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 6px;
      h3 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        font-size: 16px;
        color: #25262b;
        font-weight: 600;
        svg {
          font-size: 20px;
          color: #25262b;
          margin-right: 10px;
        }
      }
      span {
        font-size: 14px;
        color: #25262b;
        font-weight: 300;
        line-height: 22px;
        a {
          font-size: 14px;
          color: #25262b;
          font-weight: 400;
          text-decoration: none;
          cursor: pointer;
          transition: all 0.3s ease;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .integrations_contacts {
    &_in {
      width: 100%;
    }
  }
  .integrations_form {
    width: 95%;
  }
}
@media only screen and (max-width: 500px) {
  .integrations_contacts_in h3 {
    font-size: 14px;
  }
}

.page_header {
  width: calc(100% - 280px);
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  position: relative;
  z-index: 1;
  margin-left: auto;
  border-bottom: 1px solid rgba(36, 41, 48, 0.08);
  &_modal {
    position: absolute;
    z-index: 0;
    top: 60px;
    right: 20px;
    min-width: 200px;
    color: #25262b;
    border: 0 solid rgba(0, 0, 0, 0.15);
    border-radius: 0.4375rem;
    box-shadow:
      0 50px 100px rgba(50, 50, 93, 0.1),
      0 15px 35px rgba(50, 50, 93, 0.15),
      0 5px 15px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    opacity: 0;
    transform: translateY(-100px);
    &--show {
      animation: showModal 0.3s ease;
      transform: translateY(0);
      opacity: 1;
      z-index: 3;
    }
    h6 {
      font-weight: 400;
      width: 100%;
      color: #25262b;
      text-transform: uppercase;
      font-size: 14px;
      padding: 10px 20px 0 20px;
    }
    h3 {
      font-weight: 600;
      width: 100%;
      color: #25262b;
      text-transform: lowercase;
      font-size: 14px;
      padding: 10px 20px 0 20px;
    }
    button {
      font-size: 14px;
      width: 100%;
      text-align: left;
      color: #25262b;
      margin-top: 10px;
      padding: 15px 20px 20px 20px;
      font-weight: 500;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
      border-top: 1px solid rgba(#999, 0.2);
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      svg {
        font-size: 18px;
        color: #25262b;
        margin-right: 5px;
      }
      &:hover {
        background: #f3f3f4;
      }
    }
  }
  &_in {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_navigation_btn {
      background: #25262b;
      margin-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      padding: 2px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        transform: rotate(180deg);
      }
      svg {
        font-size: 22px;
      }
    }
    &_burger {
      display: none;
      img {
        width: 100px;
        margin-left: 10px;
      }
      svg {
        font-size: 22px;
        color: #000;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          transform: rotate(180deg);
        }
      }
    }
  }
  &_profile {
    background: #fff;
    border: none;
    border-radius: 100px;
    transition: all 0.3s ease;
    cursor: pointer;
    font-size: 16px;
    text-transform: lowercase;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transform: translateY(-3px);
    }
    svg {
      font-size: 30px;
      color: #25262b;
      transition: all 0.3s ease;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .page_header {
    width: 100%;
  }
  .page_header_in_navigation_btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page_header_modal {
    right: 10px;
    top: 55px;
  }
  .page_header_in_burger {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .page_header h1 {
    font-size: 13px;
  }
  .page_header {
    padding: 15px 10px;
  }
}

@media only screen and (max-width: 350px) {
  .page_header_in_burger img {
    display: none;
  }
}

@keyframes showModal {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

* {
  font-size: 62.5%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  &::-webkit-scrollbar {
    height: 1px !important;
    width: 1px !important;
  }
  &::-webkit-scrollbar-track {
    border-radius: 100px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(#999, 0.1);
    border-radius: 100px;
    transition: all 0.3s ease;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(#999, 0.1);
  }
  & input[type='number']::-webkit-inner-spin-button,
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  & input:focus {
    outline: none;
  }
  &::placeholder {
    color: #999;
    font-weight: 400;
    opacity: 1;
  }
  &::-ms-input-placeholder {
    color: #999;
    font-weight: 400;
  }
}

body {
  background: #f8f9fa;
}
html,
body,
#root {
  height: 100%;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: rgba(#000, 0.8);
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #e74c3c;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

button {
  text-transform: capitalize;
}
.switcher {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  min-height: 50px;
  & input[type='checkbox'] {
    -webkit-appearance: none;
    background: #f8f9fa;
    width: 60px;
    padding: 0 !important;
    height: 25px;
    border: none !important;
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    position: relative;
    left: 0;
    cursor: pointer;
  }
  & input:checked[type='checkbox'] {
    background: #f8f9fa;
  }
  & input[type='checkbox']:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: rgba(#e74c3c, 1);
    transition: all 0.5s ease;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    margin-left: 3px;
  }
  & input:checked[type='checkbox']::before {
    left: 34px;
    background: #25262b;
  }
}
.rws-close {
  display: none !important;
}
.lng_drop_down {
  width: 100%;
  button {
    width: 100% !important;
    height: 50px;
    border: 1px solid #cad1d7 !important;
    border-radius: 6px;
    background: #fff !important;
    padding: 16px 15px !important;
    margin: 8px 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    color: #25262b !important;
    font-size: 12px !important;
    font-weight: 600;
    &:hover {
      transform: translateY(0) !important;
    }
    span {
      font-size: 12px !important;
      svg {
        font-size: 20px !important;
      }
    }
  }
  ul {
    width: 100% !important;
    border: 1px solid #cad1d7;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    max-height: 250px;
    margin-top: 5px !important;
    .ReactFlagsSelect-module_filterBox__3m8EU {
      position: static;
    }
    input {
      border: 1px solid #cad1d7 !important;
      border-radius: 6px !important;
      height: 40px !important;
      color: #25262b !important;
      font-size: 12px !important;
    }
    li {
      width: 100% !important;
      span {
        font-size: 14px !important;
        svg {
          font-size: 20px !important;
        }
      }
    }
  }
}
.tooltipClass {
  font-size: 11px !important;
  font-family: inherit;
  text-transform: lowercase;
  font-weight: 300 !important;
  z-index: 7;
}
.css-1ckov0h-MuiSvgIcon-root {
  width: 22px !important;
  height: 22px !important;
}
.css-1uent87-MuiButtonBase-root-MuiButton-root {
  color: #000 !important;
  &:hover {
    background: #000 !important;
    color: #fff !important;
  }
}

@media (hover: hover) {
  .css-u45mo9-MuiButtonBase-root-MuiButton-root-MuiPickersToolbarButton-root:hover {
    --variant-containedBg: #fff !important;
    --variant-textBg: #fff !important;
    --variant-outlinedBorder: #fff !important;
    --variant-outlinedBg: #fff !important;
  }
}

.css-u45mo9-MuiButtonBase-root-MuiButton-root-MuiPickersToolbarButton-root:hover {
  --variant-containedBg: #fff !important;
  --variant-textBg: #fff !important;
  --variant-outlinedBorder: #fff !important;
  --variant-outlinedBg: #fff !important;
}

.css-5wpvtd-MuiClockNumber-root {
  font-size: 16px !important;
}
.css-1qltlow-MuiTabs-indicator,
.css-umzx0k-MuiClock-pin,
.css-pncb2q-MuiClockPointer-root,
.css-1h2qg9i-MuiClockPointer-root {
  background: #000 !important;
}
.css-1fiopb0-MuiClockPointer-thumb {
  background: #fff !important;
  border-color: #000 !important;
}
.css-f53ilk-MuiClockPointer-thumb {
  background: #000 !important;
  border-color: #000 !important;
}
.css-8cn20p-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #000 !important;
}
.css-6mw38q-MuiTypography-root {
  display: none !important;
}
.css-4k4mmf-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-innj4t-MuiPickersYear-yearButton.Mui-selected,
.css-qct7wd-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background: #000 !important;
  color: #fff !important;
  font-size: 14px !important;
}
.css-119y2x5-MuiStack-root,
.css-168e4me-MuiPickersToolbar-root-MuiDateTimePickerToolbar-root,
.css-15fu35s-MuiDialogActions-root {
  padding: 0 !important;
}
.css-4k4mmf-MuiButtonBase-root-MuiPickersDay-root,
.css-1uent87-MuiButtonBase-root-MuiButton-root,
.css-innj4t-MuiPickersYear-yearButton,
.css-qct7wd-MuiButtonBase-root-MuiPickersDay-root {
  font-size: 14px !important;
}
.css-6mw38q-MuiTypography-root,
.css-17f9e7e-MuiTypography-root-MuiDayCalendar-weekDayLabel {
  font-size: 14px !important;
}
.css-1kkr7v2-MuiTypography-root-MuiPickersToolbarText-root,
.css-1chuxo2-MuiPickersCalendarHeader-label {
  font-size: 16px !important;
}
.css-pnwp68-MuiDateTimePickerToolbar-timeDigitsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page {
  background: #f8f9fa;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &_in {
    height: 100%;
    width: 100%;
    background-clip: border-box;
    z-index: 1;
    padding: 0 20px;
    &_header {
      display: flex;
      width: 100%;
      align-items: center;
      background: #f8f9fa;
      justify-content: flex-start;
      padding: 15px 0;
      height: 70px;
      text-transform: capitalize;
      span {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #25262b;
        transition: all 0.3s ease;
        cursor: pointer;
        margin-right: 5px;
        text-transform: capitalize;
        &:hover {
          text-decoration: underline;
        }
      }
      p {
        font-size: 16px;
        font-weight: 300;
        color: #555;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-transform: lowercase;
      }
      h2 {
        font-size: 20px;
        font-weight: 600;
        color: #25262b;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg {
          font-size: 22px;
          color: #25262b;
          transition: all 0.3s ease;
          cursor: pointer;
          margin-left: 5px;
          &:hover {
            color: #25262b;
            transform: rotate(360deg);
          }
        }
      }
      &_back {
        margin-left: auto !important;
        border: none;
        background: #25262b;
        color: #fff;
        transition: all 0.3s;
        font-size: 12px;
        font-weight: 400;
        padding: 8px 12px;
        cursor: pointer;
        border-radius: 4px;
        letter-spacing: 0.055em;
        height: 35px;
        &:hover {
          transform: translateY(-3px);
        }
      }
      &_btn {
        border: 1px solid #25262b;
        background: #25262b;
        color: #fff;
        transition: all 0.3s;
        font-size: 12px;
        font-weight: 400;
        padding: 8px 12px;
        cursor: pointer;
        border-radius: 4px;
        letter-spacing: 0.055em;
        margin-left: 10px;
        height: 35px;
        display: flex;
        align-items: center;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          transform: translateY(-3px);
        }
        &--add {
          //background: #25262b;
          //color: #fff;
          //border: 1px solid #25262b;
          border: 1px solid #d8fbe7;
          background: #d8fbe7;
          color: #255037;
        }
        &--mode {
          border: 1px solid #25262b;
          background: #25262b;
          color: #fff;
          margin-left: 10px;
        }
      }
      &_calendar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f8f9fa;
        font-weight: 300 !important;
        font-size: 14px !important;
        border-radius: 6px;
        padding: 8px 0;
        background-clip: padding-box;
        box-shadow: none;
        transition: all 0.3s ease;
        border: 1px solid #ced4db;
        margin-left: 10px;
      }
      &_filter {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        .drop_down {
          width: 100%;
          background: #f8f9fa;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &_in {
            min-height: 35px;
            border: 1px solid #ced4db;
            width: fit-content;
            padding: 0;
            &:hover {
              border: 1px solid #ced4db;
              transform: translateY(-3px);
            }
            .drop_down_search {
              padding: 0 0 0 15px;
              .drop_down_input {
                background: #f8f9fa;
                font-weight: 300 !important;
                font-size: 14px !important;
              }
            }
          }
        }
      }
      &_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        height: 34px;
        width: 34px;
        border-radius: 100px;
        background: #f8f9fa;
        border: 1px solid #ced4db;
        svg {
          font-size: 16px;
          color: #25262b;
          transition: all 0.3s ease;
        }
        &:hover {
          background: #25262b;
          border: 1px solid #25262b;
          svg {
            color: #fff;
          }
        }
      }
    }
    &_content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      overflow: auto;
      border: 1px solid rgba(36, 41, 48, 0.08);
      background: #fff;
      border-radius: 12px;
      flex-direction: column;
      &_table {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-width: 800px;
        &_invoice {
          color: #25262b !important;
          margin-left: 5px !important;
        }
        &_status {
          background: #f3f3f4;
          min-width: 60px;
          border: none !important;
          transition: all 0.3s !important;
          font-size: 11px !important;
          color: #25262b;
          font-weight: 300 !important;
          padding: 0 8px !important;
          height: 18px !important;
          cursor: default !important;
          border-radius: 12px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          text-transform: uppercase !important;
          margin-left: 10px;
          &--paid {
            background: #d8fbe7;
            color: #255037;
          }
          &--canceled {
            background: rgba(#e74c3c, 0.1);
            color: #e74c3c;
          }
        }
        &_review {
          justify-content: flex-end !important;
          display: flex;
          align-items: center;
          svg {
            font-size: 22px !important;
            margin-right: 5px !important;
          }
          &_note {
            background: #f3f3f4;
            letter-spacing: 0.055em !important;
            border: none !important;
            transition: all 0.3s !important;
            font-size: 11px !important;
            color: #25262b;
            font-weight: 300 !important;
            padding: 0 8px !important;
            height: 18px !important;
            border-radius: 12px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            text-transform: uppercase !important;
            margin-left: 10px;
            margin-right: 0 !important;
            cursor: pointer;
            &:hover {
              background: #25262b !important;
              color: #fff !important;
            }
          }
        }
        &_date {
          color: #555 !important;
          font-weight: 300 !important;
          &--left {
            margin-left: auto !important;
          }
        }
        &_price {
          color: #555 !important;
          font-weight: 300 !important;
          margin-left: 10px !important;
        }
      }
      &_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        height: 34px;
        width: 34px;
        border-radius: 100px;
        background: #fff;
        border: 1px solid rgba(36, 41, 48, 0.08);
        margin-right: 5px;
        svg {
          font-size: 16px;
          color: #25262b;
          transition: all 0.3s ease;
          margin: 0 !important;
        }
        &:hover {
          background: #25262b;
          border: 1px solid #25262b;
          svg {
            color: #fff;
            transform: scale(1.1);
          }
        }
      }
      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &_icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 25px !important;
          }
          &__notification {
            svg {
              color: rgba(#e74c3c, 1) !important;
            }
          }
          &__call {
            svg {
              color: #2dce89 !important;
            }
          }
        }
      }
      &_bold {
        background-color: #25262b;
        color: #fff;
        letter-spacing: 0.055em;
        margin: 10px 0 10px 10px;
        border: none;
        transition: all 0.3s;
        font-size: 14px;
        height: 31px;
        font-weight: 400;
        padding: 6px 12px;
        cursor: pointer;
        border-radius: 6px;
      }
      &_id {
        background-color: #25262b;
        color: #fff;
        letter-spacing: 0.055em;
        margin: 10px 0 10px 10px;
        border: none;
        height: 31px;
        transition: all 0.3s;
        font-size: 14px;
        font-weight: 400;
        padding: 6px 12px;
        cursor: pointer;
        border-radius: 6px;
        &:last-child {
          margin-right: 0;
        }
      }
      &_actions {
        padding-left: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &_btn {
          font-size: 12px;
          line-height: 1.5;
          padding: 4px 12px;
          border-radius: 4px;
          color: #fff;
          cursor: pointer;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          letter-spacing: 0.055em;
          justify-content: center;
          font-weight: 400;
          svg {
            font-size: 18px !important;
            color: #fff;
            path {
              stroke: #fff;
            }
          }
          &--orders {
            background: #25262b;
            border: 1px solid #25262b;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            svg {
              margin-right: 5px;
            }
            &:hover {
              transform: translateY(-3px);
              background: rgba(#25262b, 1);
              border: 1px solid rgba(#25262b, 1);
            }
          }
          &--transfer {
            background: rgba(#25262b, 1);
            border: 1px solid rgba(#25262b, 0);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
            &:hover {
              transform: translateY(-3px);
              background: rgba(#25262b, 1);
              border: 1px solid rgba(#25262b, 1);
            }
          }
          &--pay {
            background: rgba(#8db864, 1);
            border: 1px solid rgba(#8db864, 0);
            margin-bottom: 10px;
            margin-top: 10px;
            &:hover {
              transform: translateY(-3px);
              background: rgba(#2dce89, 1);
              border: 1px solid rgba(#2dce89, 1);
            }
          }
          &--reset {
            background: rgba(#25262b, 1);
            border: 1px solid rgba(#25262b, 0);
            margin-right: 10px;
            border: 1px solid #d8fbe7;
            background: #d8fbe7;
            svg {
              color: #35724e !important;
            }
            &:hover {
              transform: translateY(-3px);
            }
          }
          &--edit {
            background: rgba(#25262b, 1);
            border: 1px solid rgba(#25262b, 0);
            margin-right: 10px;
            &:hover {
              background: rgba(#25262b, 1);
              border: 1px solid rgba(#25262b, 1);
            }
          }
          &--delete {
            background: rgba(#e74c3c, 1);
            border: 1px solid rgba(#e74c3c, 0);
            margin-right: 10px;
            &:hover {
              background: rgba(#e74c3c, 1);
              border: 1px solid rgba(#e74c3c, 1);
            }
          }
          &--qr {
            background: rgba(#25262b, 1);
            border: 1px solid rgba(#25262b, 0);
            margin-right: 10px;
            &:hover {
              transform: translateY(-3px);
            }
          }
          &--passcode {
            background: rgba(#25262b, 1);
            border: 1px solid rgba(#25262b, 0);
            &:hover {
              transform: translateY(-3px);
              background: rgba(#25262b, 1);
              border: 1px solid rgba(#25262b, 1);
            }
          }
          &:hover {
            transform: translateY(-3px);
          }
        }
        &_icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          cursor: pointer;
          transition: all 0.3s ease;
          height: 34px;
          width: 34px;
          border-radius: 100px;
          background: #f3f3f4;
          margin-right: 10px;
          svg {
            font-size: 16px;
            color: #25262b;
            transition: all 0.3s ease;
            margin: 0 !important;
          }
          &:hover {
            background: #25262b;
            svg {
              color: #fff;
              transform: scale(1.1);
            }
          }
        }
        &_more {
          margin-right: 20px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          &:hover {
            transform: scale(1.1);
          }
          svg {
            transform: rotate(180deg);
            font-size: 20px !important;
            cursor: pointer;
            color: #25262b;
            fill: #25262b;
            transition: all 0.3s ease;
          }
          &_btn {
            font-size: 14px;
            width: 100%;
            text-align: left;
            color: #25262b;
            padding: 10px 10px 10px 10px;
            font-weight: 500;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease;
            border-bottom: 1px solid rgba(#999, 0.2);
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }
            svg {
              font-size: 16px;
              color: #25262b;
              margin-right: 5px;
            }
            &:hover {
              background: #f3f3f4;
            }
          }
        }
        &_popup {
          position: fixed;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 200px;
          z-index: 0;
          min-width: 200px;
          border: 0 solid rgba(0, 0, 0, 0.15);
          border-radius: 0.4375rem;
          box-shadow:
            0 50px 100px rgba(50, 50, 93, 0.1),
            0 15px 35px rgba(50, 50, 93, 0.15),
            0 5px 15px rgba(0, 0, 0, 0.1);
          background-color: #fff;
          opacity: 0;
          transform: translateY(-100px);
          &--review {
            padding: 4px 8px;
            width: 300px !important;
            p {
              font-weight: 300;
              font-size: 13px;
              color: #25262b;
              line-height: 18px;
            }
          }
          &--show {
            animation: showMorePopup 0.3s ease;
            transform: translateY(0);
            opacity: 1;
            z-index: 5;
          }
        }
      }
      &_status {
        letter-spacing: 0.055em;
        margin: 10px 0 10px 10px;
        border: none;
        transition: all 0.3s;
        font-size: 14px;
        font-weight: 400;
        height: 31px;
        padding: 6px 12px;
        cursor: pointer;
        border-radius: 6px;
        &--paid,
        &--free {
          background: #d8fbe7;
          color: #255037;
          min-width: 60px;
          border: none !important;
          transition: all 0.3s !important;
          font-size: 11px !important;
          font-weight: 300 !important;
          padding: 0 8px !important;
          height: 18px !important;
          cursor: default !important;
          border-radius: 12px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          text-transform: uppercase !important;
        }
        &--unpaid,
        &--busy,
        &--canceled {
          background: rgba(231, 76, 60, 0.1);
          color: #e74c3c;
          min-width: 60px;
          border: none !important;
          transition: all 0.3s !important;
          font-size: 11px !important;
          font-weight: 300 !important;
          padding: 0 8px !important;
          height: 18px !important;
          cursor: default !important;
          border-radius: 12px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          text-transform: uppercase !important;
        }
      }
      .form {
        padding: 20px 15px;
      }
    }
    &_pagination {
      width: 100%;
      padding: 5px 10px;
    }
  }
}
.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 15px;
  &_columns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &_column {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-right: 10px;
    &_radio {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      label {
        margin-right: auto;
      }
    }
    &_img {
      width: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      overflow: hidden;
      background: #fff;
      &_btn_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      svg {
        font-size: 80px;
        color: #999;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }
      img {
        width: 100%;
        background-position: center;
        background-size: contain;
        object-position: center;
        object-fit: contain;
        vertical-align: middle;
      }
      &--small {
        height: 80px !important;
        width: auto !important;
        img {
          object-fit: fill !important;
        }
      }
    }
    .drop_down {
      margin: 8px 0;
      &_search {
        height: 30px;
      }
      input {
        &:hover,
        &:focus {
          border: none !important;
        }
      }
    }
    & input {
      display: block;
      width: 100%;
      height: 50px;
      color: #25262b;
      border: 1px solid #cad1d7;
      border-radius: 6px;
      padding: 5px 10px;
      background-color: #fff;
      background-clip: padding-box;
      box-shadow: none;
      font-size: 15px;
      transition: all 0.3s ease;
      &:focus,
      &:focus-visible,
      &:hover {
        outline: none;
      }
    }
    & input[disabled] {
      background: #f5f5f5;
      cursor: default;
      border: 1px solid #f5f5f5;
      &:hover {
        outline: none;
        border: 1px solid #e0e0e0;
      }
    }
    & textarea {
      display: block;
      width: 100%;
      height: 100px;
      color: #25262b;
      border: 1px solid #cad1d7;
      border-radius: 6px;
      padding: 5px 10px;
      background-color: #fff;
      background-clip: padding-box;
      box-shadow: none;
      font-size: 15px;
      transition: all 0.3s ease;
      &:focus,
      &:focus-visible,
      &:hover {
        border: 1px solid #25262b;
        outline: none;
      }
    }
    & label {
      font-size: 14px;
      font-weight: 600;
      color: #25262b;
      margin-bottom: 5px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        margin-left: 5px;
        font-size: 10px;
        font-weight: 400;
        color: #25262b;
      }
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        background-size: cover;
        margin-left: 5px;
      }
    }
    & button {
      border: 1px solid #25262b;
      background: #25262b;
      color: #fff;
      transition: all 0.3s;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.055em;
      padding: 6px 12px;
      width: 80px;
      cursor: pointer;
      border-radius: 6px;
      margin-top: 10px;
      &:hover {
        transform: translateY(-3px);
      }
    }
    input[type='file'] {
      display: none;
    }
  }
  &_color_picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    &_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      font-size: 16px;
    }
    &_color {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: 0;
      height: 50px;
      width: 50px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border: 1px solid #cad1d7;
    }
  }
  &_actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &_btn {
      border: 1px solid #25262b;
      background: #25262b;
      color: #fff;
      transition: all 0.3s;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.055em;
      padding: 6px 8px;
      width: 200px;
      height: 40px !important;
      cursor: pointer;
      border-radius: 6px;
      margin-top: 10px;
      .loading {
        padding: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        transform: translateY(-3px);
      }
      &--delete {
        background: rgba(#e74c3c, 1);
        border: 1px solid rgba(#e74c3c, 1);
        color: #fff;
      }
      &--loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px !important;
        .loading {
          padding: 0;
        }
      }
    }
  }
  &_file_btn {
    border: 1px solid #25262b;
    font-size: 14px !important;
    font-weight: 400 !important;
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 6px 12px;
    background: #25262b;
    color: #fff;
    letter-spacing: 0.055em;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center !important;
    color: #fff !important;
    &--uploaded {
      background: #fff;
      border: none;
      color: #25262b !important;
      &:hover {
        transform: translateY(0) !important;
      }
    }
    svg {
      margin-left: 5px;
      font-size: 16px;
      color: #fff;
      transition: all 0.3s ease;
    }
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 13px;
        color: #999;
        transition: all 0.3s ease;
      }
    }
    &:hover {
      transform: translateY(-3px);
    }
  }
  &_translation {
    border: none !important;
    background: none !important;
    transition: all 0.3s;
    padding: 5px 0 !important;
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100% !important;
    p {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.055em;
      color: #25262b !important;
      text-align: left;
    }
    span {
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 12px;
        color: #25262b;
        transition: all 0.3s ease;
      }
    }
    &:hover {
      transform: translateY(-3px);
    }
  }
  &_error {
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 0;
    &--left {
      justify-content: flex-start !important;
    }
    &_name {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 13px !important;
        color: #e74c3c !important;
        text-align: center;
        font-weight: 300 !important;
        margin: 0 !important;
        width: calc(100% - 25px);
      }
      svg {
        font-size: 18px;
        color: #e74c3c;
        width: 20px;
        margin-right: 5px;
      }
    }
  }
  &_input_wrapper {
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: flex-start;
    position: relative;
    &_icon {
      display: flex;
      align-self: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      svg {
        font-size: 22px;
      }
    }
  }
}

.loading_fullscreen {
  width: 100%;
  height: 100%;
  background: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &--fixed {
    position: fixed;
    z-index: 999 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes showMorePopup {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes openTablesDetailsPage {
  0% {
    transform: translateX(550px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes openTablesDetailsPageMobile {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1024px) {
  .form_columns {
    flex-direction: column;
    padding: 0;
  }
  .form_column:last-child {
    margin-right: 0;
  }
}
@media only screen and (max-width: 800px) {
  .page_in {
    width: 100%;
  }
  .page_in_header {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    h2 {
      width: 100%;
    }
  }
  .page_in_content_actions_icon {
    margin-right: 0;
  }
  .page_in_header_btn {
    font-size: 12px;
  }
  .page_in_header_back {
    margin-right: 10px;
  }
  .page_in_content_actions_popup--show {
    right: 10px !important;
  }
  .page_in_content .form {
    padding: 0 10px 20px 10px;
  }
  .page_in_pagination {
    padding: 10px;
  }
  .form_actions_btn {
    font-size: 12px;
  }
  .form {
    padding: 20px 5px;
  }
  .form_column {
    margin-right: 0;
  }
  .Toastify__toast-container {
    top: 65px !important;
    width: 95% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .Toastify__toast {
    margin-bottom: 10px !important;
  }
  .Toastify__toast-container {
    height: 70px;
  }
  .tooltipClass {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .page_in_header_calendar {
    width: 300px;
  }
  .page_in_header {
    padding: 5px 0;
    justify-content: flex-end;
    align-items: center;
    h2 {
      padding: 5px 0;
      justify-content: flex-start;
    }
    &_btn--add {
      margin-left: auto;
    }
  }
  .page_in {
    padding: 0 10px;
  }
}

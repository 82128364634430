.menu {
  &_in {
    &_header {
      &_actions {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      &_lng {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        &_drop_down {
          margin-left: 10px;
          button {
            cursor: pointer;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 10px 5px 0;
            font-family: inherit;
            color: #25262b;
            border: 1px solid #cad1d7;
            border-radius: 6px;
            background: transparent;
            height: 35px;
            font-weight: 600;
            span {
              font-size: 12px !important;
              svg {
                font-size: 18px;
              }
            }
          }
          ul {
            width: 250px;
            border: 1px solid #cad1d7;
            box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            max-height: 250px;
            padding: 0 !important;
            .ReactFlagsSelect-module_filterBox__3m8EU {
              position: static;
            }
            input {
              border: 1px solid #cad1d7;
              font-size: 12px;
              border-radius: 6px;
              height: 100%;
            }
            li {
              width: 100%;
              padding: 8px 10px;
              margin: 0 !important;
              transition: all 0.3s ease;
              &:hover {
                background: #f3f3f4;
              }
              span {
                font-size: 14px;
                color: #25262b !important;
                svg {
                  font-size: 18px;
                }
              }
            }
          }
        }
        &_label {
          background-color: #e2e2e2;
          color: #25262b;
          border: none;
          transition: all 0.3s;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 600;
          padding: 4px 10px;
          border-radius: 4px;
          letter-spacing: 0.055em;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
        }
        &_btn {
          font-size: 12px;
          line-height: 1.5;
          padding: 5px 10px;
          border-radius: 6px;
          color: #fff;
          box-shadow:
            0 4px 6px rgba(50, 50, 93, 0.11),
            0 1px 3px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          letter-spacing: 0.055em;
          justify-content: center;
          font-weight: 600;
          background: rgba(#25262b, 1);
          border: 1px solid rgba(#25262b, 0);
          margin-left: 5px;
          &:hover {
            transform: translateY(-3px);
            background: rgba(#25262b, 1);
            border: 1px solid rgba(#25262b, 1);
          }
          &--delete {
            background: rgba(#e74c3c, 1);
            border: 1px solid rgba(#e74c3c, 0);
            &:hover {
              transform: translateY(-3px);
              background: rgba(#e74c3c, 1);
              border: 1px solid rgba(#e74c3c, 1);
            }
          }
          &--import {
            background: #8db864;
            border: 1px solid rgba(#8db864, 0);
            &:hover {
              transform: translateY(-3px);
              background: rgba(#8db864, 1);
              border: 1px solid rgba(#8db864, 1);
            }
          }
        }
      }
    }
    &_content {
      &_lng {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 35px;
          height: 35px;
          border-radius: 100px;
          background-position: center;
          object-fit: contain;
          margin-right: 5px;
          margin-left: 10px;
        }
      }
      &_actions {
        padding-left: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &_btn {
          font-size: 12px;
          line-height: 1.5;
          padding: 5px 10px;
          border-radius: 6px;
          color: #fff;
          margin-right: 10px;
          box-shadow:
            0 4px 6px rgba(50, 50, 93, 0.11),
            0 1px 3px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          letter-spacing: 0.055em;
          justify-content: center;
          font-weight: 600;
          svg {
            margin-right: 4px;
            font-size: 15px !important;
            color: #fff;
          }
          &--edit {
            background: rgba(#25262b, 1);
            border: 1px solid rgba(#25262b, 0);
            &:hover {
              transform: translateY(-3px);
              background: rgba(#25262b, 1);
              border: 1px solid rgba(#25262b, 1);
            }
          }
          &--delete {
            background: rgba(#e74c3c, 1);
            border: 1px solid rgba(#e74c3c, 0);
            &:hover {
              transform: translateY(-3px);
              background: rgba(#e74c3c, 1);
              border: 1px solid rgba(#e74c3c, 1);
            }
          }
        }
      }
      &_importing {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        h2 {
          width: 100%;
          text-align: center;
          margin-bottom: 5px;
          font-size: 16px;
          color: #25262b;
          font-weight: 600;
        }
        h3 {
          width: 100%;
          text-align: center;
          margin-bottom: 3px;
          font-size: 14px;
          color: #25262b;
          font-weight: 400;
        }
        p {
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
          font-size: 14px;
          color: #25262b;
          font-weight: 400;
        }
        .load-wrapp {
          width: 120px;
          float: left;
          margin: 0 10px 20px 0;
          border-radius: 5px;
          text-align: center;
        }
        .load-wrapp:last-child {
          margin-right: 0;
        }
        .square {
          width: 12px;
          height: 12px;
          border-radius: 4px;
          background-color: #25262b;
        }
        .load-7 .square {
          animation: loading 1.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
        }
      }
      &_labels {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        &_label {
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 20px 10px;
          border-bottom: 1px solid #f8f9fa;
          &_name {
            font-size: 14px;
            color: #25262b;
            transition: all 0.3s ease;
            cursor: pointer;
            height: 100%;
            margin-bottom: 1px;
            font-weight: 400;
            padding: 5px 0;
          }
          &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            list-style: none;
            font-size: 13px;
            font-weight: 400;
            border-radius: 100px;
            padding: 5px 8px;
            text-align: center;
            margin-bottom: 5px;
            height: 30px;
            border-width: 1px;
            border-style: solid;
            img {
              margin-right: 10px;
              height: 20px;
              object-fit: contain;
            }
            p {
              font-size: 14px;
              color: #25262b;
              transition: all 0.3s ease;
              cursor: pointer;
              height: 100%;
              margin-bottom: 1px;
              text-transform: lowercase;
              font-weight: 400;
              &:hover {
                transform: translateY(-3px);
              }
            }
          }
        }
      }
    }
  }
  &_labels {
    width: 100%;
    padding: 0 15px;
    margin: 10px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    &_title {
      font-size: 14px;
      font-weight: 600;
      color: #25262b;
      margin-bottom: 5px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &_btn {
      border: 1px solid #25262b;
      background: #25262b;
      color: #fff;
      transition: all 0.3s;
      font-size: 14px;
      font-weight: 400;
      padding: 6px 12px;
      cursor: pointer;
      border-radius: 6px;
      letter-spacing: 0.055em;
      margin-left: auto;
      height: 35px;
      &:hover {
        transform: translateY(-3px);
        background: rgba(#25262b, 1);
        border: 1px solid rgba(#25262b, 1);
      }
    }
    &_in {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      &_emojis {
        border: 1px solid #cad1d7 !important;
        margin-left: auto;
        width: 100% !important;
      }
      &_name {
        display: block;
        height: 50px;
        width: 50%;
        color: #25262b;
        border: 1px solid #cad1d7;
        border-radius: 6px;
        padding: 16px 15px;
        background-color: #fff;
        background-clip: padding-box;
        box-shadow: none;
        font-size: 14px;
        transition: all 0.3s ease;
        margin: 5px 10px 5px 0;
        &:focus,
        &:focus-visible,
        &:hover {
          border: 1px solid #25262b;
          outline: none;
        }
      }
      &_picker {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        border: 1px solid #cad1d7;
        border-radius: 6px;
        background-color: #fff;
        box-shadow: none;
        position: relative;
        padding: 0 10px;
        width: 220px;
        margin: 5px 10px 5px 0;
        &:last-child {
          margin-right: 0;
        }
        &_color {
          color: #25262b;
          font-size: 14px;
          margin-right: 5px;
          font-weight: 400;
        }
        &_label {
          font-size: 12px;
          color: #25262b;
          position: absolute;
          height: 20px;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          text-align: center;
          border: 1px solid #fff;
          background: #fff;
        }
      }
      &_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 10px 5px 0;
        height: 50px;
        &_in {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            margin-left: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s ease;
            svg {
              transition: all 0.3s ease;
              color: #e74c3c;
              font-size: 18px;
            }
            &:hover {
              transform: translateY(-3px);
            }
          }
          img {
            width: 40px;
            height: 40px;
            background-position: center;
            object-fit: contain;
          }
        }
      }
      &_preview {
        list-style: none;
        font-size: 16px;
        font-weight: 400;
        border-radius: 100px;
        padding: 6px 12px;
        height: 40px;
        text-align: center;
        margin: 10px 0 5px auto;
        min-width: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-width: 1px;
        border-style: solid;
        img {
          margin-right: 10px;
          height: 25px;
          object-fit: contain;
        }
      }
    }
  }
  &_modal {
    position: fixed;
    top: 0;
    right: 0;
    width: 550px;
    height: 100%;
    z-index: 3;
    background: #fff;
    transform: translateX(550px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    &--active {
      animation: openReorderPage 0.5s ease;
      transform: translateX(0);
    }
    &_title {
      font-size: 16px;
      font-weight: 600;
      color: #25262b;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      padding: 0 15px;
    }
    &_in {
      width: 100%;
      height: calc(100% - 80px - 40px);
      overflow: auto;
      padding: 0 15px 0 10px;
      &_loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
    &_actions {
      margin-top: auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 80px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 0 20px;
      &_btn {
        font-size: 14px;
        line-height: 1.5;
        padding: 5px 10px;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        letter-spacing: 0.055em;
        justify-content: center;
        &--cancel {
          background: #fff;
          border: none;
          color: #25262b;
          text-transform: lowercase;
          font-weight: 400;
          &:hover {
            transform: translateY(-3px);
          }
        }
        &--submit {
          background: rgba(#25262b, 1);
          border: 1px solid rgba(#25262b, 0);
          font-weight: 600;
          margin-left: 20px;
          box-shadow:
            0 4px 6px rgba(50, 50, 93, 0.11),
            0 1px 3px rgba(0, 0, 0, 0.1);
          color: #fff;
          &:hover {
            transform: translateY(-3px);
          }
        }
      }
    }
  }
}

@keyframes loading {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(120px, 0) rotate(360deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
@keyframes openReorderPage {
  0% {
    transform: translateX(550px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes openReorderPageMobile {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 600px) {
  .menu_modal {
    width: 100%;
    &--active {
      animation: openReorderPageMobile 0.5s ease;
      transform: translateX(0);
    }
  }
  .menu_labels_title {
    font-size: 14px;
  }
  .menu_labels {
    padding: 0 10px;
  }
  .menu_in_content_labels {
    padding: 0 15px;
  }
}

.shop_basket {
  width: 500px;
  height: 100%;
  position: fixed;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  right: -100%;
  top: 0;
  z-index: 4;
  opacity: 1;
  border-left: 1px solid rgba(36, 41, 48, 0.08);
  flex-direction: column;
  .shop_category_product {
    margin-right: 0;
  }
  &--dark {
    .shop_checkout_actions_btn {
      background: #8db864 !important;
    }
    .shop_basket_in,
    .shop_basket_footer {
      background: var(--dynamic-color) !important;
    }
    .shop_category_product_name,
    .shop_category_product_price,
    .shop_category_product_actions span,
    .shop_category_product_basket_qty,
    .shop_category_product_basket_name,
    .shop_category_product_basket_price,
    .shop_category_product_basket_name_options p {
      color: #fff !important;
    }
    .shop_basket_header {
      background: var(--dynamic-color) !important;
    }
    .shop_basket_header svg {
      color: #777 !important;
    }
    .shop_basket_header p,
    h2 {
      color: #fff !important;
    }
  }
  &--show {
    right: 0;
    z-index: 4;
    opacity: 1;
    animation: showBasketModal 0.3s ease;
  }
  &_header {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0 10px 10px;
    background: #fff;
    h2 {
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      color: #25262b;
      text-transform: uppercase;
    }
    p {
      font-size: 14px;
      font-weight: 600;
      color: #25262b;
      margin-left: auto;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      margin-left: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 18px;
        transition: all 0.3s ease;
        color: #777;
      }
      &:hover {
        svg {
          color: #e74c3c;
        }
      }
    }
    svg {
      font-size: 25px;
      cursor: pointer;
      margin-right: 10px;
      color: var(--dynamic-color);
    }
  }
  &_title {
    width: 100%;
    text-align: left;
    display: block;
    padding: 10px 10px 10px 10px;
    font-weight: 600;
    font-size: 20px;
    color: var(--dynamic-color);
  }
  &_empty {
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 30px;
    p {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: var(--dynamic-color);
      padding: 10px 0 5px 0;
    }
    svg {
      font-size: 30px;
      color: var(--dynamic-color);
    }
  }
  &_in {
    width: 100%;
    height: calc(100% - 45px);
    background: #fff;
    transition: all 0.3s ease;
    overflow-y: auto;
    padding-bottom: 5px;
  }
  .shop_category_product {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: none !important;
  }
}

@keyframes showBasketModal {
  0% {
    opacity: 0;
    z-index: 1;
    right: -100%;
  }
  100% {
    right: 0;
    z-index: 4;
    opacity: 1;
  }
}

@media only screen and (max-width: 1000px) {
  .shop_basket_header {
    h2 {
      font-size: 16px;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .shop_basket {
    width: 100%;
    .shop_category_product_actions {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .shop_category_product_actions_in {
      margin-left: auto;
    }
  }
}

.passcode_popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
  &_in {
    width: 400px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px 0;
    &--active {
      animation: showModal 0.3s ease;
      transform: translateY(0);
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid rgba(#999, 0.2);
      padding: 20px 30px;
      h2 {
        font-family: inherit;
        font-weight: 600;
        line-height: 1.5;
        font-size: 16px;
        color: #32325d;
        width: 100%;
        text-align: start;
      }
      svg {
        transition: all 0.3s ease;
        cursor: pointer;
        font-size: 20px;
        color: #32325d;
        &:hover {
          transform: translateY(-3px);
        }
      }
    }
    h3 {
      color: #32325d;
      font-size: 16px;
      text-align: center;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(#999, 0.2);
      margin-bottom: 10px;
    }
    h2 {
      font-size: 14px;
      color: #32325d;
      text-align: center;
      width: 100%;
      line-height: 22px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 0 10px;
    }
    &_actions {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 0 10px;
      &_btn {
        transition: all 0.3s;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.055em;
        padding: 8px 10px;
        width: 80px;
        cursor: pointer;
        border-radius: 6px;
        margin-top: 5px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          transform: translateY(-3px);
        }
        &--cancel {
          background: transparent;
          border: none;
          color: #32325d;
          font-weight: 500;
        }
        &--submit {
          background: #e74c3c;
          border: 1px solid #e74c3c;
          color: #fff;
        }
      }
    }
  }
}

@keyframes showModal {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  .passcode_popup_in {
    width: 95%;
  }
}

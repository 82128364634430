.terminal_nav {
  width: 500px;
  height: 100%;
  border-right: 1px solid rgba(#999, 0.2);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  background: #f8f9fa;
  &_item {
    list-style: none;
    width: 100%;
    padding: 0 15px;
    background: #fff;
    min-height: 50px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: all 0.3s ease;
    border-bottom: 1px solid rgba(#999, 0.2);
    color: #25262b;
    &--active {
      background: rgba(#25262b, 1);
      border-bottom: 1px solid rgba(#fff, 1);
      color: #fff;
    }
    &:hover {
      background: rgba(#25262b, 1);
      border-bottom: 1px solid rgba(#fff, 1);
      color: #fff;
    }
  }
}

@media only screen and (max-width: 900px) {
  .terminal_nav {
    width: 250px;
  }
}
@media only screen and (max-width: 600px) {
  .terminal_nav {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    height: 60px;
    overflow-x: auto;
  }
  .terminal_nav_item {
    border: none;
    font-size: 14px;
    padding: 0 5px;
    min-width: 150px;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    word-break: break-word;
    height: 100%;
    &:hover {
      border: none;
    }
  }
}

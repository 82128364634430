.terminal_basket {
  width: 700px;
  height: 100%;
  overflow: auto;
  border-left: 1px solid rgba(#999, 0.2);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #f8f9fa;
  &_title {
    width: 100%;
    padding: 0 10px 0 10px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #25262b;
    background: #25262b;
    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      height: 34px;
      width: 34px;
      border-radius: 100px;
      background: #f8f9fa;
      margin-right: 10px;
      svg {
        font-size: 16px;
        color: #25262b;
        transition: all 0.3s ease;
        margin: 0 !important;
      }
      &:hover {
        background: #25262b;
        svg {
          color: #fff;
          transform: scale(1.1);
        }
      }
    }
    p {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }
  }
  &_list {
    width: 100%;
    overflow: auto;
    height: calc(100% - 60px - 60px - 60px);
    &_item {
      list-style: none;
      padding: 15px 15px 5px 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 60px;
      font-size: 14px;
      font-weight: 400;
      border: none;
      border-bottom: 1px solid rgba(#999, 0.2);
      color: #25262b;
      background: #fff;
      .no_data {
        padding: 0 !important;
        margin: 0 !important;
      }
      &_wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
      }
      &_options {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin: 5px 0;
        p {
          font-size: 14px;
          font-weight: 400;
          padding: 3px 0;
          margin-right: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 75px);
        }
        span {
          margin-right: 5px;
          border: none;
          background-color: rgba(#25262b, 1);
          color: #fff;
          transition: all 0.3s;
          font-size: 10px;
          font-weight: 400;
          padding: 6px 5px;
          cursor: pointer;
          border-radius: 4px;
          letter-spacing: 0.055em;
          width: 65px;
          text-align: center;
        }
      }
      &_header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 5px;
        &_name {
          font-size: 14px;
          font-weight: 400;
          padding: 3px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 200px;
        }
        &_price {
          font-size: 14px;
          font-weight: 400;
          padding: 3px 0;
        }
        span {
          margin-right: 5px;
          border: none;
          color: #25262b;
          transition: all 0.3s;
          font-size: 13px;
          font-weight: 400;
          cursor: pointer;
          border-radius: 16px;
          letter-spacing: 0.055em;
          margin-left: auto;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 10px;
          background: #fff;
        }
      }
    }
  }
  &_amount {
    width: 100%;
    height: 60px;
    margin-top: auto;
    border-top: 1px solid rgba(#999, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    background: #fff;
    p {
      font-size: 14px;
      font-weight: 400;
    }
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  &_actions {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    &_submit {
      border: 1px solid #25262b;
      background: #25262b;
      color: #fff;
      transition: all 0.3s;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.055em;
      padding: 12px 10px;
      cursor: pointer;
      border-radius: 6px;
      width: 70%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        transform: translateY(-3px);
      }
      &--loading {
        background: #fff;
      }
    }
    &_btn {
      border: none;
      width: 25px;
      height: 25px;
      font-size: 12px;
      border-radius: 100px;
      font-weight: 900;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.1);
      }
      &--add {
        background-color: rgba(#25262b, 1);
        width: 30px;
        height: 30px;
        svg {
          font-size: 20px;
          path {
            stroke: #fff;
          }
        }
      }
      &--remove {
        background: rgba(#e74c3c, 0.2);
        width: 30px;
        height: 30px;
        svg {
          font-size: 20px;
          color: #e74c3c;
        }
      }
    }
  }
  &_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 34px;
    width: 34px;
    border-radius: 100px;
    background: #f8f9fa;
    margin-right: 10px;
    svg {
      font-size: 16px;
      color: #25262b;
      transition: all 0.3s ease;
      margin: 0 !important;
    }
    &:hover {
      background: #25262b;
      svg {
        color: #fff;
        transform: scale(1.1);
      }
    }
  }
  &_popup {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    z-index: 0;
    min-width: 200px;
    border: 0 solid rgba(0, 0, 0, 0.15);
    border-radius: 0.4375rem;
    box-shadow:
      0 50px 100px rgba(50, 50, 93, 0.1),
      0 15px 35px rgba(50, 50, 93, 0.15),
      0 5px 15px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    opacity: 0;
    transform: translateY(-100px);
    &--show {
      animation: showMorePopup 0.3s ease;
      transform: translateY(0);
      opacity: 1;
      z-index: 12;
    }
    &_overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      z-index: 11;
    }
  }
  &_btn {
    border: 1px solid #25262b;
    background: #25262b;
    color: #fff;
    transition: all 0.3s;
    font-size: 12px;
    font-weight: 400;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    letter-spacing: 0.055em;
    height: 35px;
    display: flex;
    align-items: center;
    margin-left: auto;
    &:hover {
      transform: translateY(-3px);
    }
  }
}

@keyframes hideBasket {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}

@media only screen and (max-width: 1190px) {
  .terminal_basket {
    border: none;
    box-shadow: none;
    width: 100%;
    position: absolute;
    z-index: 3;
    top: 0;
    right: -100%;
    &_title {
      padding: 0;
    }
    &--show {
      animation: hideBasket 0.3s ease;
      right: 0;
    }
  }
  .terminal_basket_icon {
    margin-left: auto;
  }
  .terminal_basket_title_icon {
    margin-right: auto;
  }
}
@media only screen and (max-width: 400px) {
  .terminal_basket_list_item_header_name {
    width: 150px;
  }
}
@media only screen and (max-width: 350px) {
  .terminal_basket_list_item_header {
    margin-bottom: 0;
  }
  .terminal_basket_list_item_header_price {
    padding: 5px 0 0 0;
  }
}

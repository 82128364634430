.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  &--transparent {
    background-color: transparent;
  }
  &--white {
    background-color: #fff;
  }
  &--dark {
    background-color: #25262b !important;
  }
  &--blur {
    background-color: rgba(#000, 0.7) !important;
  }
}

.orders_list_product {
  list-style: none;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px dashed #868e96;
  &_name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 13px;
    font-weight: 300;
    color: #25262b;
    padding: 0 0 5px 0;
    &_remove {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(#e74c3c, 1);
      border-radius: 100px;
      margin-right: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.1);
      }
      svg {
        font-size: 18px;
        color: #fff;
      }
    }
    span {
      color: #25262b;
      list-style: none;
      font-size: 13px;
      font-weight: 300;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &_price {
      font-size: 14px;
      font-weight: 400;
      color: #25262b;
      margin-left: 5px;
    }
    &_qty {
      font-weight: 600 !important;
      border: none !important;
      background-color: #f3f3f4 !important;
      color: #25262b !important;
      transition: all 0.3s !important;
      font-size: 10px !important;
      padding: 4px 10px !important;
      border-radius: 3px !important;
      letter-spacing: 0.055em !important;
      margin-left: auto;
    }
  }
  &_options {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 300;
    margin-top: 6px;
    span {
      margin-right: 5px;
      border: none;
      background-color: rgba(#25262b, 1);
      color: #fff;
      transition: all 0.3s;
      font-size: 12px;
      font-weight: 400;
      padding: 5px 10px;
      cursor: pointer;
      border-radius: 6px;
      letter-spacing: 0.055em;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #25262b;
      padding-right: 5px;
      line-height: 20px;
      display: inline;
      text-align: left;
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .orders_list_product_options p {
    font-size: 12px;
  }
  .orders_list_product_options span {
    font-size: 10px;
  }
}

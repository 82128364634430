.shop_category_product_details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-bottom: 1px solid #25262b;
  width: 500px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  transform: translate(0, 0);
  z-index: 4;
  opacity: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
  &--dark {
    background-color: var(--dynamic-color) !important;
    .shop_category_product_details_header {
      background: var(--dynamic-color) !important;
    }
    .shop_category_product_details_header h2,
    .shop_category_product_details_wrapper_name,
    .shop_category_product_details_extras_extra_name {
      color: #fff !important;
    }
    .shop_category_product_details_wrapper_description {
      color: #999;
    }
    .shop_category_product_details_wrapper_price,
    .shop_category_product_details_extras_extra_price {
      color: #fff !important;
    }
    .shop_category_product_details_header svg {
      color: #777 !important;
    }
    .shop_category_product_details_in {
      background: var(--dynamic-color) !important;
    }
    .shop_category_product_details_note textarea {
      background: var(--dynamic-color) !important;
      color: #fff !important;
      border: 1px solid #25262b !important;
      &::placeholder {
        color: rgba(#999, 1);
        font-weight: 400;
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: rgba(#999, 1);
        font-weight: 400;
      }
    }
    .shop_category_product_details_extras_extra input[type='checkbox']:checked,
    .shop_category_product_details_footer button {
      background: var(--dynamic-color) !important;
    }
    .shop_category_product_details_footer {
      background: var(--dynamic-color);
    }
    .shop_category_product_details_footer button {
      background: #8db864 !important;
    }
  }
  &--show {
    z-index: 4;
    opacity: 1;
    animation: showProductDetails 0.4s ease;
  }
  &_header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 10px;
    background: #fff;
    h2 {
      font-size: 18px;
      color: #25262b;
    }
    svg {
      font-size: 20px;
      cursor: pointer;
      margin-right: 10px;
      color: #fff;
      z-index: 10;
      padding: 3px;
      border-radius: 100px;
      background: rgba(#999, 0.5);
      width: 25px;
      height: 25px;
    }
  }
  &_in {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    background: #fff;
  }
  &_img {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    img {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      width: 100%;
      height: 100%;
      background-position: center;
      object-position: center;
      object-fit: contain;
    }
  }
  &_note {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px;
    margin-top: 10px;
    margin-bottom: 20px;
    & textarea {
      display: block;
      width: 100%;
      height: 80px;
      color: #25262b;
      border: 1px solid #25262b;
      border-radius: 6px;
      padding: 6px 8px;
      background-color: #fff;
      background-clip: padding-box;
      box-shadow: none;
      font-size: 14px;
      transition: all 0.3s ease;
      &::placeholder {
        color: #25262b;
        font-weight: 300;
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: #25262b;
        font-weight: 300;
      }
      &:focus,
      &:hover {
        outline: none !important;
      }
    }
  }
  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 5px 15px;
    &_name {
      font-weight: 600;
      color: #25262b;
      width: 100%;
      font-size: 16px;
      padding-bottom: 10px;
    }
    &_description {
      width: 100%;
      font-size: 14px;
      font-weight: 300;
      color: #25262b;
      padding: 0 0 15px 0;
    }
    &_price {
      font-size: 16px;
      color: #25262b !important;
      font-weight: 600;
      margin-right: auto;
    }
  }
  &_extras {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px;
    flex-direction: column;
    &_extra {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      width: 100%;
      cursor: pointer;
      &_label {
        border-radius: 100px;
        border: 1px solid #25262b;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;
        span {
          background: none;
          width: 14px;
          height: 14px;
          border-radius: 100px;
        }
        &--active {
          span {
            background: #25262b;
          }
        }
      }
      input[type='checkbox'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        display: inline-block;
        width: 18px !important;
        height: 18px !important;
        color: #fff;
        padding: 1px;
        background-clip: content-box;
        border: 2px solid rgb(200, 200, 200);
        background-color: rgb(200, 200, 200);
        border-radius: 100px;
      }
      input[type='checkbox']:checked {
        background-color: #25262b;
      }
      &_name {
        font-size: 14px;
        font-weight: 400;
        color: #25262b;
        margin-left: 10px;
        text-align: left;
        width: calc(100% - 100px);
      }
      &_price {
        font-size: 14px;
        font-weight: 400;
        color: #25262b;
        margin-left: auto;
        text-align: left;
      }
    }
  }
  &_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    height: 60px;
    background: #fff;
    button {
      width: calc(100% - 10px);
      padding: 5px 10px;
      border-radius: 6px;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--dynamic-color) !important;
      color: #fff;
      height: 50px !important;
      animation: showBasketBtn 0.3s ease;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      margin: 5px auto 0 auto;
    }
  }
}

@keyframes showProductDetails {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    z-index: 4;
    transform: translate(0, 0);
  }
}

@media only screen and (max-width: 500px) {
  .shop_category_product_details {
    width: 100%;
    height: 100%;
    bottom: 0;
    top: unset;
    margin: 0 auto;
    box-shadow: 0px 0px 16px -1px rgba(0, 0, 0, 0.2);
    left: 50%;
    right: unset;
    transform: translate(-50%, 0);
  }
  .shop_category_product_details_header {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }
  @keyframes showProductDetails {
    0% {
      transform: translate(-50%, 100%);
    }
    100% {
      z-index: 4;
      transform: translate(-50%, 0);
    }
  }
}

.menu_import {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 3;
  width: 100%;
  height: 100%;
  &_in {
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow:
      0 15px 35px rgba(50, 50, 93, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.17);
    flex-direction: column;
    pointer-events: auto;
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 0.4375rem;
    outline: 0;
    background-clip: padding-box;
    padding: 20px 0;
    transform: translateY(-100px);
    &--active {
      animation: showModal 0.3s ease;
      transform: translateY(0);
    }
    .form {
      padding: 20px;
      &_actions {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid rgba(#999, 0.2);
      padding: 20px 30px;
      &_title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        h2 {
          font-family: inherit;
          font-weight: 600;
          line-height: 1.5;
          font-size: 16px;
          color: #25262b;
          text-align: start;
        }
        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
          background-size: cover;
          margin-left: 5px;
        }
      }
      svg {
        transition: all 0.3s ease;
        cursor: pointer;
        font-size: 20px;
        color: #25262b;
        &:hover {
          transform: translateY(-3px);
        }
      }
    }
    &_template {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 12px 10px;
      border: 1px solid #25262b;
      border-radius: 6px;
      margin-bottom: 10px;
      &_remove {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 45px;
        &_descrip {
          font-family: inherit;
          font-weight: 600;
          line-height: 1.5;
          font-size: 14px;
          color: #25262b;
          text-align: start;
          &--success {
            color: #25262b;
          }
        }
        &_btn {
          width: unset !important;
          background: none !important;
          border: none !important;
          border-radius: 0 !important;
          margin: 0 !important;
          padding: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: #e74c3c;
            font-size: 20px;
          }
          &:hover {
            transform: unset;
          }
        }
      }
      &_btn {
        background: none;
        border: none;
        color: #25262b;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg {
          margin-left: 5px;
          font-size: 18px;
          color: #25262b;
        }
        &:hover {
          transform: translateY(-3px);
        }
      }
      svg {
        font-size: 25px;
        margin-right: 10px;
        color: #25262b;
      }
      &_descrip {
        font-family: inherit;
        font-weight: 400;
        line-height: 1.5;
        font-size: 14px;
        color: #25262b;
        width: 100%;
        text-align: start;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        &--success {
          margin-top: 5px;
          color: green;
        }
        a {
          text-decoration: none;
          line-height: 1.5;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          color: #25262b;
          align-items: center;
          justify-content: flex-start;
          display: inline-flex;
          margin-top: 5px;
          transition: all 0.3s ease;
          text-transform: capitalize;
          svg {
            margin-left: 5px;
            font-size: 16px;
            color: #25262b;
            transition: all 0.3s ease;
          }
          &:hover {
            color: #25262b;
            text-decoration: underline;
            svg {
              color: #25262b;
            }
          }
        }
      }
    }
  }
}

@keyframes showModal {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  .menu_import_in {
    width: 95%;
  }
}

.terminal_product {
  width: 100%;
  list-style: none;
  padding: 5px 15px 5px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 60px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(#999, 0.2);
  color: #25262b;
  cursor: pointer;
  &:hover {
    background: #f8f9fa;
  }
  &_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    flex-wrap: wrap;
  }
  &_options {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    &_name {
      font-size: 14px;
      font-weight: 400;
      padding: 3px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 250px;
    }
    &_price {
      font-size: 14px;
      font-weight: 400;
      padding: 3px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span {
      margin-right: 5px;
      border: none;
      background: #25262b;
      transition: all 0.3s;
      font-size: 10px !important;
      border-radius: 12px !important;
      color: #fff;
      font-weight: 300 !important;
      height: 18px !important;
      padding: 3px 8px !important;
      text-transform: uppercase !important;
    }
  }
  &_actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    height: 100%;
    span {
      font-size: 13px;
      font-weight: 400;
      margin: 0 8px;
    }
    &_btn {
      border: none;
      width: 30px;
      height: 30px;
      font-size: 12px;
      border-radius: 100px;
      font-weight: 900;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &--add {
        background-color: rgba(#25262b, 1);
        width: 30px;
        height: 30px;
        svg {
          font-size: 20px;
          path {
            stroke: #fff;
          }
        }
      }
      &--remove {
        background: rgba(#e74c3c, 0.2);
        width: 30px;
        height: 30px;
        svg {
          font-size: 20px;
          color: #e74c3c;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .terminal_product_options_name {
    width: 150px;
  }
}
@media only screen and (max-width: 650px) {
  .terminal_product_options_name {
    width: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .terminal_product_options_name {
    width: 250px;
  }
}
@media only screen and (max-width: 550px) {
  .terminal_product_options_name {
    width: 150px;
  }
}
@media only screen and (max-width: 450px) {
  .terminal_product_options span {
    display: none;
  }
  .terminal_product_options_name {
    width: 200px;
    overflow-wrap: break-word;
  }
  .terminal_product_options_price {
    margin-left: 10px;
  }
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0 0 0;
  &_limit {
    width: 50px;
  }
  & p {
    font-size: 12px;
    color: #888;
    margin-right: 15px;
    font-weight: 300;
  }
  &_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & svg {
      font-size: 1.4rem;
      cursor: pointer;
      color: #444;
    }
    &_page {
      list-style: none;
      font-size: 14px;
      padding: 3px 6px;
      cursor: pointer;
      transition: all 0.3s;
      color: #444;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      margin-right: 3px;
      margin-left: 3px;
      width: 25px;
      height: 25px;
      background: transparent;
      border-radius: 6px;
      &:hover {
        color: #fff;
        background: #25262b;
      }
      &--active {
        color: #fff;
        background: #25262b;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .pagination p {
    font-size: 10px;
  }
}

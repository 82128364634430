.shop_checkout_back_url_page {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  bottom: 0;
  z-index: 4;
  flex-direction: column;
  right: 0;
  opacity: 1;
}

@media only screen and (max-width: 500px) {
  .shop_checkout_back_url_page_inn {
    width: 100%;
  }
}

.not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e2e2e2;
  height: 100%;
  flex-direction: column;
  width: 100%;
  &_img {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }
  }
  p {
    font-size: 22px;
    color: #25262b;
    margin-top: 10px;
    font-weight: 400;
    text-align: center;
  }
  & button {
    padding: 10px 20px;
    background: #25262b;
    display: block;
    border: 1px solid #25262b;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
    &:hover {
      background: #fff;
      color: #25262b;
      border: 1px solid #fff;
    }
  }
}

@media only screen and (max-width: 480px) {
  .not_found_img {
    width: 100%;
  }
  .not_found_img img {
    width: 90%;
  }
  .not_found p {
    font-size: 25px;
  }
}
